import { createEnumParam, useQueryParam, withDefault } from 'use-query-params'

import { useMediaQuery } from '@mui-components'

const profileItemParam = createEnumParam([
  undefined,
  'profile',
  'address',
  'insurance',
  'health-assistance',
  'health-history',
  'sexual-health',
  'about',
])

export function useProfileItem() {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  return useQueryParam('profile-item', withDefault(profileItemParam, isTablet ? 'profile' : undefined), { updateType: 'replaceIn' })
}
