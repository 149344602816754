import { useFormik } from 'formik'

import { severities } from '@shared/utils'

import { Button, Dialog, LoadingButton, MenuItem, Stack, TextField, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import InputControl from '@components/InputControl'
import { SlideUp } from '@components/Transitions'

import { getInitialValues, toApiData, validationSchema } from './Update.utils'

/**
 * AllergyUpdate component displays a form to create or edit an allergy.
 *
 * @param {object} props - The component props.
 * @param {object} props.data - The allergy data to edit.
 * @param {function} props.onUpdate - Function to call when the form is submitted.
 * @param {boolean} props.open - Controls whether the dialog is open.
 * @param {function} props.onClose - Function to call when the dialog is closed.
 * @returns {JSX.Element} The AllergyUpdate component.
 */
export default function AllergyUpdate({ data, onUpdate, open, onClose }) {
  const isEdit = Boolean(data)
  const dialogTitle = isEdit ? 'Edit Allergy' : 'New Allergy'
  const dialogTitleId = 'allergy-update-dialog-title'

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={SlideUp} aria-labelledby={dialogTitleId} role="dialog">
      <FormHeader title={dialogTitle} onClose={onClose} data-testid="allergy-update-drawer" id={dialogTitleId} />
      <Form allergy={data} onUpdate={onUpdate} onClose={onClose} />
    </Dialog>
  )
}

/**
 * Form component for updating or creating an allergy.
 *
 * @param {object} props - The component props.
 * @param {object} props.allergy - The allergy data.
 * @param {function} props.onUpdate - Function to call when the form is submitted.
 * @param {function} props.onClose - Function to call when the form is closed.
 * @returns {JSX.Element} The Form component.
 */
function Form({ allergy, onUpdate, onClose }) {
  const formik = useFormik({
    initialValues: getInitialValues(allergy),
    validationSchema,
    onSubmit: (values) => onUpdate(toApiData(values)).then(onClose),
  })

  return (
    <form onSubmit={formik.handleSubmit} aria-labelledby="allergy-form-heading">
      <Stack sx={{ maxWidth: 'sm', width: '100%', mx: 'auto', px: 2, py: 3, gap: 3 }}>
        <Typography id="allergy-form-heading" variant="h6" component="h1" sx={{ display: 'none' }}>
          {allergy ? 'Edit Allergy' : 'New Allergy'}
        </Typography>
        <Typography>Once the health history has been updated, any further changes can only be made by your healthcare provider.</Typography>
        <Stack spacing={2}>
          <InputControl field="name" formikProps={formik} variant="standard">
            <TextField
              autoFocus
              required
              fullWidth
              autoComplete="off"
              disabled={formik.isSubmitting}
              inputProps={{ 'aria-label': 'Allergy Name' }}
            />
          </InputControl>
          <InputControl field="reaction" formikProps={formik} variant="standard">
            <TextField required fullWidth autoComplete="off" disabled={formik.isSubmitting} inputProps={{ 'aria-label': 'Reaction' }} />
          </InputControl>
          <InputControl field="severity" formikProps={formik} variant="standard">
            <TextField
              required
              fullWidth
              autoComplete="off"
              disabled={formik.isSubmitting}
              select
              inputProps={{ 'aria-label': 'Select Severity' }}
            >
              {severities.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </InputControl>
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button disabled={formik.isSubmitting} onClick={onClose} variant="outlined" data-testid="cancel" aria-label="Cancel">
            Cancel
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
            variant="contained"
            data-testid="submit"
            aria-label={allergy ? 'Update allergy' : 'Create allergy'}
          >
            {allergy ? 'Update' : 'Create'}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
