import { SvgIcon } from '@mui-components'

export default function HelpMoney({ sx, ...rest }) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" sx={[{ width: 'auto', height: 'auto' }, sx]} {...rest}>
      <path
        d="M19 16h-2c0-1.2-.75-2.28-1.87-2.7L8.97 11H1v11h6v-1.44l7 1.94 8-2.5v-1c0-1.66-1.34-3-3-3zM3 20v-7h2v7H3zm10.97.41L7 18.48V13h1.61l5.82 2.17c.34.13.57.46.57.83 0 0-1.99-.05-2.3-.15l-2.38-.79-.63 1.9 2.38.79c.51.17 1.04.26 1.58.26H19c.39 0 .74.23.9.56l-5.93 1.84z"
        fill="currentColor"
      />
      <path
        d="M17.192 6.95c-1.115-.295-1.474-.6-1.474-1.075 0-.545.496-.925 1.326-.925.875 0 1.199.425 1.228 1.05h1.086c-.035-.86-.55-1.65-1.577-1.905V3h-1.473v1.08c-.953.21-1.72.84-1.72 1.805 0 1.155.939 1.73 2.309 2.065 1.228.3 1.473.74 1.473 1.205 0 .345-.24.895-1.326.895-1.012 0-1.41-.46-1.463-1.05H14.5c.059 1.095.864 1.71 1.808 1.915V12h1.473v-1.075c.958-.185 1.719-.75 1.719-1.775 0-1.42-1.194-1.905-2.308-2.2z"
        fill="currentColor"
        fillOpacity=".72"
      />
    </SvgIcon>
  )
}
