const styles = {
  container: {
    top: 0,
    position: 'fixed',
    right: 80,
    zIndex: 1202,
    p: 1,
    transform: 'translateY(-67px)',
    transition: 'transform 300ms ease-in-out',
    backgroundColor: 'primary.light',
    borderRadius: 0,
    borderBottomLeftRadius: (theme) => theme.spacing(1),
    borderBottomRightRadius: (theme) => theme.spacing(1),
  },
  tongue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: 32,
    height: 32,
    position: 'absolute',
    right: 0,
    bottom: -32,
    backgroundColor: 'primary.light',
    color: 'primary.dark',
    borderRadius: 0,
    borderBottomLeftRadius: (theme) => theme.spacing(1),
    borderBottomRightRadius: (theme) => theme.spacing(1),
  },
  select: {
    '& .goog-te-combo': {
      width: '100%',
      height: 24,
      borderRadius: 0.5,
      color: 'text.secondary',
      border: '1px solid',
      borderColor: 'primary.main',
    },
  },
}

export default styles
