import dayjs from 'dayjs'
import * as Yup from 'yup'

import { getUserSetting } from '@shared/hooks/src/useUserSetting'
import { ContactMethod, UserSettingName } from '@shared/utils'

export const validationSchema = Yup.object().shape({
  nickname: Yup.string(),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string().min(10, 'Please enter valid phone').max(10, 'Please enter valid phone'),
  email: Yup.string().email('A valid email address is required').max(255).required('Email is required'),
  contactMethod: Yup.string().required('Contact Preference is required'),
  dob: Yup.lazy(() =>
    Yup.date()
      .min('1901-01-01', `Must be after 01/01/1901`)
      .max(dayjs().subtract(18, 'year'), `Must be at least 18yo`)
      .nullable()
      .typeError('Invalid Date')
      .required('Date of Birth is required')
  ),
  language: Yup.string().required('Language is required'),
  pronouns: Yup.string(),
  preferredCommunication: Yup.string(),
})

export const getInitialValues = (user) => {
  const contactMethodSetting = getUserSetting(user, UserSettingName.ContactMethod)
  const preferredCommSetting = getUserSetting(user, UserSettingName.PreferredCommunication)

  return {
    nickname: user?.nickname ?? '',
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phone: user?.phone ?? '',
    email: user?.email ?? '',
    contactMethod: contactMethodSetting?.value ?? ContactMethod.Phone,
    dob: user?.dob ? dayjs(user.dob) : null,
    language: user?.language ?? '',
    pronouns: user?.pronouns ?? '',
    docIdMimeType: 'image/png',
    photoId: user?.photoId ?? null,
    preferredCommunication: preferredCommSetting?.value ?? '',
  }
}

export const formikToApiData = (data) => {
  return {
    patient: {
      nickname: data.nickname,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
      email: data.email,
      dob: data.dob.format('YYYY-MM-DD'),
      language: data.language || null,
      pronouns: data.pronouns || null,
    },
    communication_settings: {
      preferred_communication: data.preferredCommunication || 'Email',
      contact_method: data.contactMethod,
    },
  }
}

export const formikDocIdToApiData = (data) => {
  return {
    mime_type: data.docIdMimeType,
    photo_id: data.photoId.split(',')[1],
  }
}

export const getMissingInfo = (user) => {
  const values = getInitialValues(user)
  const contactMethodSetting = getUserSetting(user, UserSettingName.ContactMethod)
  const emailVerifiedSetting = getUserSetting(user, UserSettingName.EmailVerified)
  const phoneVerifiedSetting = getUserSetting(user, UserSettingName.PhoneVerified)

  const errors = {}
  if (!values.firstName) {
    errors.firstName = 'Add a first name'
  }
  if (!values.lastName) {
    errors.lastName = 'Add a last name'
  }
  if (values.phone && !phoneVerifiedSetting?.value && !user?.smsOptOut) {
    errors.phoneVerification = 'Please verify your phone number'
  }
  if (!values.email) {
    errors.email = 'Add an email address'
  }
  if (values.email && !emailVerifiedSetting?.value) {
    errors.emailVerification = 'Please verify your email'
  }
  if (!values.dob) {
    errors.dob = 'Add a birthdate'
  }
  if (!values.language) {
    errors.language = 'Select your preferred language'
  }
  if (!values.photoId) {
    errors.photoId = 'Upload image of Government ID'
  }
  if (!contactMethodSetting?.value) {
    errors.contactMethod = 'Please choose a contact preference'
  }
  return errors
}
