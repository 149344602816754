import React from 'react'
import PropTypes from 'prop-types'

import { useGTPageLoad } from '@utils/googleTags'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Typography } from '@mui-components'

CountySelectionDialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** List of counties */
  counties: PropTypes.arrayOf(PropTypes.string),

  /** Called after any action or the dialog is closed */
  onCancel: PropTypes.func.isRequired,

  /** Called when selecting a county */
  onSelect: PropTypes.func.isRequired,
}

/**
 * CountySelectionDialog component displays a dialog allowing the user to select a county.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {Array} props.counties - List of counties to display.
 * @param {function} props.onCancel - Function to call when the dialog is closed.
 * @param {function} props.onSelect - Function to call when a county is selected.
 * @returns {JSX.Element} The CountySelectionDialog component.
 */
export default function CountySelectionDialog({ open, counties, onCancel, onSelect }) {
  const testId = 'county-selection-dialog'

  useGTPageLoad({ page: 'enroll_county', enabled: open })

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      PaperProps={{ 'data-testid': testId }}
      aria-labelledby={`${testId}-title`}
      role="dialog"
    >
      <DialogTitle id={`${testId}-title`}>Which county do you live in</DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Typography sx={{ px: 3, py: 1 }}>Your new location requires joining a new community partner that serves your area.</Typography>
        <List aria-label="List of available counties">
          {counties.map((county) => (
            <ListItemButton
              key={county}
              data-testid={`${testId}-county-${county}`}
              onClick={() => onSelect(county)}
              aria-label={`Select ${county} county`}
            >
              <ListItemText primary={county} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid={`${testId}-cancel`} aria-label="Cancel and close dialog">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
