import ErrorBase from '@components/ErrorBase'

/**
 * NotFound component displays an error message when the requested page is not found.
 *
 * @param {object} props - The component props.
 * @param {Error|string} props.error - The error object or message.
 * @param {function} props.resetErrorBoundary - Function to reset the error boundary.
 * @returns {JSX.Element} The NotFound component.
 */
export default function NotFound({ error, resetErrorBoundary }) {
  return (
    <ErrorBase
      title="Well that’s not good."
      subtitle="We can’t find that page, but don’t worry, there is plenty more on the home page!"
      code={404}
      details={error}
      reset={resetErrorBoundary}
    />
  )
}
