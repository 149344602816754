import { forwardRef } from 'react'
import MuiLoadingButton from '@mui/lab/LoadingButton'

const LoadingButton = forwardRef(({ children, ...props }, ref) => {
  return (
    <MuiLoadingButton ref={ref} {...props}>
      {/* Wrap content with span because of https://github.com/mui/material-ui/issues/27853 */}
      <span>{children}</span>
    </MuiLoadingButton>
  )
})

LoadingButton.displayName = 'LoadingButton'

export default LoadingButton
