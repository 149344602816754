import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  hasPrep: Yup.string(),
  sexPartners: Yup.array(),
  sexAmount: Yup.string(),
  sexType: Yup.array(),

  hivExposure: Yup.string(),
  sexCondomless: Yup.string(),
  sexRisky: Yup.string(),

  hadStd: Yup.string(),
  hadSyphilis: Yup.string(),
  hadDiseases: Yup.array(),
})

export const getInitialValues = (data) => {
  return {
    hasPrep: data?.hasPrep || '',
    sexPartners: data?.sexPartners || [],
    sexAmount: data?.sexAmount || '',
    sexType: data?.sexType || [],

    hivExposure: data?.hivExposure || '',
    sexCondomless: data?.sexCondomless || '',
    sexRisky: data?.sexRisky || '',

    hadStd: data?.hadStd || '',
    hadSyphilis: data?.hadSyphilis || '',
    hadDiseases: data?.hadDiseases || [],
  }
}

export const formikToApiData = (data) => {
  return {
    has_prep: data.hasPrep,
    sex_partners: data.sexPartners,
    sex_amount: data.sexAmount,
    sex_type: data.sexType,

    hiv_exposure: data.hivExposure,
    sex_condomless: data.sexCondomless,
    sex_risky: data.sexRisky,

    had_std: data.hadStd,
    had_syphilis: data.hadSyphilis,
    had_diseases: data.hadDiseases,
  }
}
