import { useMe } from '@shared/providers/src/MeProvider'

export default function useThreadUsers(thread) {
  const me = useMe()
  return thread?.users?.filter((u) => u.id !== me.id) ?? []
}

export function getThreadName(users) {
  return users.map((u) => u.fullName).join(', ')
}
