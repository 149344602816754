/**
 * Load a script async into specified container
 * @param {String} src
 * @param {HTMLElement} container
 * @param {String} id
 */
export const loadScript = (src, container, id) => {
  if (!container || !id || document.querySelector(`script#${id}`)) {
    return
  }

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  container.appendChild(script)
}
