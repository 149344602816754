import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { DatePicker } from '@mui/x-date-pickers-pro'

import { Button, Dialog, LoadingButton, Stack, TextField, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import InputControl from '@components/InputControl'
import { SlideUp } from '@components/Transitions'

import { getInitialValues, toApiData, validationSchema } from './Update.utils'

/**
 * ConditionUpdate component displays a form to create or edit a medical condition.
 *
 * @param {object} props - The component props.
 * @param {object} props.data - The condition data to edit.
 * @param {function} props.onUpdate - Function to call when the form is submitted.
 * @param {boolean} props.open - Controls whether the dialog is open.
 * @param {function} props.onClose - Function to call when the dialog is closed.
 * @returns {JSX.Element} The ConditionUpdate component.
 */
export default function ConditionUpdate({ data, onUpdate, open, onClose }) {
  const isEdit = Boolean(data)
  const dialogTitle = isEdit ? 'Edit Condition' : 'New Condition'
  const dialogTitleId = 'condition-update-dialog-title'

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={SlideUp} aria-labelledby={dialogTitleId} role="dialog">
      <FormHeader title={dialogTitle} onClose={onClose} data-testid="condition-update-drawer" id={dialogTitleId} />
      <Form condition={data} onUpdate={onUpdate} onClose={onClose} />
    </Dialog>
  )
}

/**
 * Form component for updating or creating a condition.
 *
 * @param {object} props - The component props.
 * @param {object} props.condition - The condition data.
 * @param {function} props.onUpdate - Function to call when the form is submitted.
 * @param {function} props.onClose - Function to call when the form is closed.
 * @returns {JSX.Element} The Form component.
 */
function Form({ condition, onUpdate, onClose }) {
  const formik = useFormik({
    initialValues: getInitialValues(condition),
    validationSchema,
    onSubmit: (values) => onUpdate(toApiData(values)).then(onClose),
  })

  return (
    <form onSubmit={formik.handleSubmit} aria-labelledby="condition-form-heading">
      <Stack sx={{ maxWidth: 'sm', width: '100%', mx: 'auto', px: 2, py: 3, gap: 3 }}>
        <Typography id="condition-form-heading" variant="h6" component="h1" sx={{ display: 'none' }}>
          {condition ? 'Edit Condition' : 'New Condition'}
        </Typography>
        <Typography>Once the health history has been updated, any further changes can only be made by your healthcare provider.</Typography>
        <Stack spacing={2}>
          <InputControl field="name" formikProps={formik} variant="standard">
            <TextField
              autoFocus
              required
              fullWidth
              autoComplete="off"
              disabled={formik.isSubmitting}
              inputProps={{ 'aria-label': 'Condition Name' }}
            />
          </InputControl>
          <DatePicker
            minDate={dayjs('1901-01-01')}
            maxDate={dayjs()}
            value={formik.values.onsetYear}
            onChange={(year) => formik.setFieldValue('onsetYear', year, true)}
            disabled={formik.isSubmitting}
            views={['year']}
            slotProps={{
              textField: {
                label: 'Year',
                fullWidth: true,
                variant: 'standard',
                autoComplete: 'off',
                name: 'onsetYear',
                helperText: formik.touched.onsetYear && formik.errors.onsetYear,
                error: Boolean(formik.touched.onsetYear && formik.errors.onsetYear),
                inputProps: { 'aria-label': 'Select onset year' },
              },
            }}
          />
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button disabled={formik.isSubmitting} onClick={onClose} variant="outlined" data-testid="cancel" aria-label="Cancel">
            Cancel
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
            variant="contained"
            data-testid="submit"
            aria-label={condition ? 'Update condition' : 'Create condition'}
          >
            {condition ? 'Update' : 'Create'}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
