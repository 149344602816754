import { isDesktop } from 'react-device-detect'
import { useDropzone } from 'react-dropzone'
import { defaults } from 'lodash'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'

import { getTestId } from '@shared/utils'

import { UploadFileFilledIcon } from '@icons'
import { Avatar, Box, InputLabel, Stack, Typography } from '@components/_mui'

import FilesPreview from './FilesPreview'
import FilesRejection from './FilesRejection'

FileUploader.propTypes = {
  /** Array of files */
  files: PropTypes.array,

  /** Function to change selected files */
  onChange: PropTypes.func,

  /** Can show error state */
  error: PropTypes.bool,

  /** Dropzone disabled */
  disabled: PropTypes.bool,

  /** Can select multiple files */
  multiple: PropTypes.bool,

  /**
   * Dropzone options
   * @see https://react-dropzone.js.org/#src
   */
  dropzoneOptions: PropTypes.object,

  /** Description/Label */
  title: PropTypes.string,

  /** Description of allowed files */
  allowedDescription: PropTypes.string,

  /** Description of uploading more files (not disabled) */
  uploadMoreDescription: PropTypes.string,

  /** Can pass mui sx style */
  sx: PropTypes.object,
}

/**
 * Drag and drop file uploader component.
 * Allows users to select files by clicking or dragging and dropping files into the dropzone area.
 *
 * @param {object} props - Component props.
 * @param {string} props.title - Description or label displayed above the dropzone.
 * @param {boolean} props.error - Flag to show error state.
 * @param {Array} props.files - Array of selected files.
 * @param {function} props.onChange - Callback function when files are selected.
 * @param {boolean} props.disabled - Flag to disable the dropzone.
 * @param {string} props.uploadMoreDescription - Description shown when more files can be uploaded.
 * @param {string} props.allowedDescription - Description of allowed file types.
 * @param {object} props.dropzoneOptions - Options for react-dropzone.
 * @param {object} props.sx - Custom styles to apply to the root element.
 */
export default function FileUploader({
  title,
  error,
  files,
  onChange,
  disabled,
  uploadMoreDescription,
  allowedDescription,
  dropzoneOptions,
  sx,
  ...rest
}) {
  const testId = getTestId(rest, 'file-uploader')

  const { multiple, ...dropzoneProps } = defaults(dropzoneOptions, {
    multiple: false,
    maxFiles: 1,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/svg+xml': ['.svg'],
      'image/gif': ['.gif'],
    },
  })

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    disabled,
    multiple,
    ...dropzoneProps,
    onDropAccepted: (acceptedFiles) => {
      onChange(files && multiple ? [...files, ...acceptedFiles] : acceptedFiles)
    },
  })

  const isError = isDragReject || fileRejections.length > 0 || error

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropzoneWrapper
        {...getRootProps()}
        sx={{
          ...(!disabled && {
            '&:hover': {
              opacity: 0.65,
              cursor: 'pointer',
            },
          }),
          ...(!disabled && isDragActive && { opacity: 0.65 }),
          ...(isError && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.shades.light.8',
          }),
        }}
        disabled={disabled}
        aria-label={`${title || 'File uploader'}. Press to upload.`}
        data-testid={`${testId}-dropzone`}
      >
        {Boolean(title) && <LabelStyled shrink>{title}</LabelStyled>}
        <input {...getInputProps()} data-testid={`${testId}-input`} />
        {!files?.length && (
          <Stack spacing={2} alignItems="center" justifyContent="center" direction="column" sx={{ width: 1, textAlign: 'center' }}>
            <Avatar
              size="sm"
              sx={(theme) => ({
                bgcolor: theme.palette.primary.shades.light[8],
                '>img': { width: '50%', height: '50%' },
              })}
            >
              <UploadFileFilledIcon color={isError ? 'error' : 'primary'} aria-hidden="true" />
            </Avatar>
            <Typography variant="subtitle1">
              <LinkStyled>Click to upload</LinkStyled>
              {isDesktop ? ' or drag and drop' : ''}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {allowedDescription || 'SVG, PNG, JPG or GIF'}
            </Typography>
          </Stack>
        )}
        {fileRejections.length > 0 && <FilesRejection reasons={fileRejections} />}
        {files?.length > 0 && <FilesPreview files={files} />}
        {files?.length > 0 && !disabled && (
          <Typography variant="subtitle1" align="center" sx={{ my: 2, color: (theme) => theme.palette.primary.main }}>
            {uploadMoreDescription || 'Upload new image'}
          </Typography>
        )}
      </DropzoneWrapper>
    </Box>
  )
}

const DropzoneWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  border: `1px dashed ${theme.palette.primary.main}`,
  position: 'relative',
}))

const LinkStyled = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
}))

const LabelStyled = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  background: theme.palette.background.paper,
  position: 'absolute',
  padding: theme.spacing(0, 1),
  top: theme.spacing(-1.2),
  left: theme.spacing(1),
}))
