import React, { useState } from 'react'
import { useFormik } from 'formik'

import Image from '@shared/components/src/Image'
import { getTestId, handleError, toBase64 } from '@shared/utils'

import { Button, Dialog, Grid, Stack, TextField, Typography } from '@mui-components'
import FileUploader from '@components/FileUploader'
import FormHeader from '@components/Header/Form'
import ImageCrop from '@components/ImageCrop'
import InputControl from '@components/InputControl'
import { SlideUp } from '@components/Transitions'

import { formikToApiData, getInitialValues, validationSchema } from './Update.utils'

export default function Update({ insurance, onUpdate, open, onClose }) {
  const testId = insurance ? `update-${insurance.company}-insurance-dialog` : 'new-insurance-dialog'

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUp}
      PaperProps={{ 'data-testid': testId }}
      aria-labelledby={`${testId}-title`}
      role="dialog"
      aria-modal="true"
    >
      <Form insurance={insurance} onUpdate={onUpdate} onClose={onClose} data-testid={testId} />
    </Dialog>
  )
}

function Form({ insurance, onUpdate, onClose, ...rest }) {
  const testId = getTestId(rest)

  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [imageToCrop, setImageToCrop] = useState()

  const formik = useFormik({
    initialValues: getInitialValues(insurance),
    validationSchema,
    onSubmit: (values) => {
      const data = formikToApiData(values, insurance)
      return onUpdate(data)
        .then(onClose)
        .catch((e) => handleError(e, { showResponse: true }))
    },
  })

  const handleImageSelect = async (field, files) => {
    if (!files.length) return
    const base64 = await toBase64(files[0])
    setImageToCrop({ field, base64 })
  }

  return (
    <form noValidate onSubmit={formik.handleSubmit} aria-labelledby={`${testId}-title`}>
      <InsuranceCardExample open={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
      <FormHeader title="Insurance" onClose={onClose} id={`${testId}-title`} />
      <Stack spacing={4} px={2} py={3} maxWidth={800} mx="auto" role="form" aria-describedby={`${testId}-description`}>
        <Typography id={`${testId}-description`}>Please fill out the following information to update your insurance details.</Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControl field="subscriber" formikProps={formik}>
                <TextField
                  autoFocus
                  required
                  label="Insured Name"
                  fullWidth
                  size="small"
                  helperText="From your health insurance card. May be shown as member name, name, enrollee, or insured."
                />
              </InputControl>
            </Grid>
            <Grid item xs={12}>
              <InputControl field="company" formikProps={formik}>
                <TextField
                  required
                  label="Insurance Company"
                  fullWidth
                  size="small"
                  helperText="The name of your health insurance company, from your insurance card."
                />
              </InputControl>
            </Grid>
          </Grid>
        </div>
        <Button
          onClick={() => setIsHelpOpen(true)}
          data-testid="insurance-example-btn"
          aria-haspopup="dialog"
          aria-controls="insurance-example-dialog"
          aria-label="Get help with finding information on your insurance card"
        >
          Help with insurance card
        </Button>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputControl field="idnum" formikProps={formik}>
                <TextField required label="ID" fullWidth size="small" />
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="pcn" formikProps={formik}>
                <TextField label="PCN" fullWidth size="small" />
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="bin" formikProps={formik}>
                <TextField label="BIN" fullWidth size="small" />
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="groupnum" formikProps={formik}>
                <TextField label="GRP" fullWidth size="small" />
              </InputControl>
            </Grid>
          </Grid>
        </div>
        <FileUploader
          files={formik.values.front ? [formik.values.front] : []}
          onChange={(files) => handleImageSelect('front', files)}
          title="Front of insurance card"
          data-testid={`${testId}-front-uploader`}
        />
        <FileUploader
          files={formik.values.back ? [formik.values.back] : []}
          onChange={(files) => handleImageSelect('back', files)}
          title="Back of insurance card"
          data-testid={`${testId}-back-uploader`}
        />
        {imageToCrop && (
          <ImageCrop
            closeOnSave
            file={imageToCrop.base64}
            onSave={(base64) => {
              formik.setFieldValue(imageToCrop.field, base64)
              setImageToCrop(undefined)
            }}
            onCancel={() => setImageToCrop(undefined)}
          />
        )}
        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button onClick={onClose} variant="outlined" data-testid={`${testId}-cancel`} aria-label="Cancel updating insurance information">
            Cancel
          </Button>
          <Button
            disabled={formik.isSubmitting || !formik.dirty}
            type="submit"
            variant="contained"
            data-testid={`${testId}-update`}
            aria-label="Submit updated insurance information"
          >
            Update
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

function InsuranceCardExample({ open, onClose }) {
  return (
    <Dialog
      id="insurance-example-dialog"
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={SlideUp}
      PaperProps={{ 'data-testid': 'insurance-example-dialog' }}
      aria-labelledby="insurance-example-title"
      aria-describedby="insurance-example-description"
      role="dialog"
      aria-modal="true"
    >
      <FormHeader title="Insurance Card Example" onClose={onClose} id="insurance-example-title" />
      <Stack spacing={4} px={2} py={3} maxWidth={800} mx="auto" role="document" aria-describedby="insurance-example-description">
        <Typography id="insurance-example-description">
          Your Health Insurance Company will provide you with a card showing your information.
        </Typography>
        <Typography>The example below shows how to find the information you need to enter.</Typography>
        <Image
          src="/insurance-example.png"
          alt="Example of a health insurance card highlighting key information fields."
          duration={500}
          fit="contain"
          style={{ maxWidth: 400 }}
        />
        <Typography>If your card has a PCN, BIN, or GRP number, you can enter those as well.</Typography>
      </Stack>
    </Dialog>
  )
}
