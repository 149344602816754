import React, { useState } from 'react'

import usePubSub, { PubSubEvents } from '@shared/hooks/src/usePubSub'

import { CloseIcon } from '@icons'
import { IconButton, Snackbar, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

import { styles } from '../Notifications.utils'

/**
 * OutOfOfficeNotifications component that listens for 'UserOutOfOffice' events via PubSub
 * and displays a Snackbar notification listing users who are out of office.
 */
export default function OutOfOfficeNotifications() {
  const [open, setOpen] = useState(false)
  const [users, setUsers] = useState([])

  const testId = 'out-of-office-notification'
  const handleClose = () => setOpen(false)

  usePubSub(PubSubEvents.UserOutOfOffice, (users = []) => {
    setUsers(users)
    setOpen(true)
  })

  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      role="alert"
      aria-live="assertive"
      aria-label="Out of Office Notification"
      data-testid={`${testId}-snackbar`}
    >
      <div role="document">
        <Stack
          sx={styles.container}
          data-testid={testId}
          aria-labelledby={`${testId}-title`}
          aria-describedby={`${testId}-description`}
          component="section"
        >
          <Stack direction="row" sx={styles.header}>
            <Typography fontWeight="bold" data-testid={`${testId}-title`} component="h6" id={`${testId}-title`}>
              Out Of Office
            </Typography>
            <IconButton
              color="inherit"
              edge="end"
              onClick={(e) => {
                e.preventDefault()
                handleClose()
              }}
              data-testid={`${testId}-close`}
              aria-label="Close Out of Office Notification"
            >
              <CloseIcon fontSize="inherit" aria-hidden="true" />
            </IconButton>
          </Stack>
          <Stack spacing={2} id={`${testId}-description`}>
            {users.map((user) => (
              <Stack key={user.id}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Avatar user={user} size="xs" />
                  <Typography>{user.fullName}</Typography>
                </Stack>
                <Typography sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{user.outOfOfficeMessage}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </div>
    </Snackbar>
  )
}
