import React, { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import usePubNub from '@shared/hooks/src/usePubNub'
import { useMe } from '@shared/providers/src/MeProvider'

import { CloseIcon } from '@icons'
import { CardActionArea, IconButton, Snackbar, Stack, Typography } from '@mui-components'

import { styles } from '../Notifications.utils'

/**
 * JoinAppointment component that listens for 'join_appointment' actions via PubNub
 * and displays a notification allowing the user to join the appointment.
 */
export default function JoinAppointment() {
  const me = useMe()
  const currentPath = useLocation().pathname
  const testId = 'join-appointment-notification'

  const [open, setOpen] = useState(false)
  const [appointmentId, setAppointmentId] = useState(undefined)

  const handleClose = () => setOpen(false)

  usePubNub(`user_${me.id}`, ({ action, attributes }) => {
    if (action !== 'join_appointment') return

    setAppointmentId(attributes.id)
    setOpen(true)
  })

  return (
    <Snackbar
      open={open}
      onClose={(event, reason) => {
        if (reason === 'escapeKeyDown') handleClose()
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      role="alert"
      aria-live="assertive"
      aria-label="Join Appointment Notification"
      data-testid={`${testId}-snackbar`}
    >
      <CardActionArea
        component={RouterLink}
        to={`/app/visits/${appointmentId}`}
        replace={currentPath.startsWith('/app/visits')}
        onClick={handleClose}
        sx={styles.container}
        data-testid={testId}
        aria-labelledby={`${testId}-title`}
        aria-describedby={`${testId}-description`}
      >
        <Stack direction="row" sx={styles.header}>
          <Typography fontWeight="bold" data-testid={`${testId}-title`} component="h6" id={`${testId}-title`}>
            Join Appointment
          </Typography>
          <IconButton
            color="inherit"
            edge="end"
            onClick={(e) => {
              e.preventDefault()
              handleClose()
            }}
            data-testid={`${testId}-close`}
            aria-label="Close Join Appointment Notification"
          >
            <CloseIcon fontSize="inherit" aria-hidden="true" />
          </IconButton>
        </Stack>
        <Typography data-testid={`${testId}-description`} component="p">
          Your scheduled appointment is about to start. Please press here to enter.
        </Typography>
      </CardActionArea>
    </Snackbar>
  )
}
