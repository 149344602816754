import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const WhiteLabelApi = {
  theme: (query, config) =>
    api
      .get(QS.stringifyUrl({ url: '/theme', query }), config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default WhiteLabelApi
