import PropTypes from 'prop-types'

import { getDropzoneData, prettyBytes } from '@shared/utils'

import { Box, Paper, Typography } from '@mui-components'

RejectionFiles.propTypes = {
  /** Array of rejection reasons */
  reasons: PropTypes.array.isRequired,
}

/**
 * Show preview of uploaded files with rejection reasons.
 */
export default function RejectionFiles({ reasons }) {
  return (
    <Paper
      variant="outlined"
      role="alert"
      aria-label="File upload errors"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: 'error.shades.light.8',
      }}
    >
      {reasons.map(({ file, errors }) => {
        const { path, size } = getDropzoneData(file)

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {path}
              {size ? ` - ${prettyBytes(size)}` : ''}
            </Typography>
            <ul aria-label={`Errors for file ${path}`}>
              {errors.map((error) => (
                <Box key={error.code} component="li" sx={{ typography: 'caption', overflowWrap: 'break-word' }}>
                  {error.message}
                </Box>
              ))}
            </ul>
          </Box>
        )
      })}
    </Paper>
  )
}
