import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack, Typography } from '@mui-components'

/**
 * ConfirmSTITesting component displays a confirmation dialog for enrolling in STI Testing.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.loading - Indicates whether the confirm action is loading.
 * @param {function} props.onCancel - Function to call when the cancel button is clicked.
 * @param {function} props.onConfirm - Function to call when the confirm button is clicked.
 * @returns {JSX.Element} The ConfirmSTITesting component.
 */
export default function ConfirmSTITesting({ loading, onCancel, onConfirm }) {
  const navigate = useNavigate()
  const handleConfirm = useCallback(
    () =>
      onConfirm().then(() => {
        navigate('/app/appointments?action=schedule-appointment')
      }),
    [navigate, onConfirm]
  )

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body" aria-labelledby="confirm-sti-testing-dialog-title" role="dialog">
      <DialogTitle id="confirm-sti-testing-dialog-title">Enroll in STI Testing</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography fontWeight="bold">Getting tested regularly and taking steps to prevent STIs keeps sex sexy.</Typography>
          <Typography variant="body2">
            <b>Content:</b>
            <br />
            Schedule a visit with a provider who will order your STI testing based on your preferences and discuss STI prevention methods
            with you. Your provider will order appropriate STI tests, which may include those for chlamydia, gonorrhea, syphilis,
            trichomoniasis, and/or HIV.
          </Typography>
          <Typography variant="body2">
            <b>What if I have symptoms of a Sexually Transmitted Infection (STI)?</b>
            <br />
            For patients with STI symptoms, we recommend heading to your local department of health, urgent care, or your primary care
            doctor who can evaluate and treat you more quickly. If you are uncertain about symptoms, we are happy to meet with you and
            recommend the best course of action!
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <LoadingButton
            onClick={handleConfirm}
            variant="contained"
            loading={loading}
            data-testid="sti-testing-confirm"
            aria-label="Schedule appointment for STI Testing"
          >
            Schedule Appointment
          </LoadingButton>
          <Button onClick={onCancel} disabled={loading} data-testid="back" aria-label="Go back to treatment selection">
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
