import { useCallback, useMemo, useRef } from 'react'
import throttle from 'lodash/throttle'

import { loadScript } from '@shared/utils/src/DOM'

/**
 * Use google places autocomplete
 */
export default function useGooglePlacesAutocomplete() {
  const loaded = useRef(null)
  const autocompleteService = useRef(null)
  const mapDummy = useRef(null)
  const placesService = useRef(null)

  // Get and/or initialize services
  const getAutocompleteService = () => {
    if (!autocompleteService.current && window.google?.maps?.places) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }

    if (!autocompleteService.current) {
      return undefined
    }

    return autocompleteService.current
  }
  const getPlacesService = () => {
    if (!placesService.current && window.google?.maps) {
      mapDummy.current = new window.google.maps.Map(document.createElement('div'))
      placesService.current = new window.google.maps.places.PlacesService(mapDummy.current)
    }

    if (!placesService.current) {
      return undefined
    }

    return placesService.current
  }

  // Load library
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_KEY}&libraries=places&callback=Function.prototype`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  // Callback to find suggestions
  const getSuggestions = useMemo(
    () =>
      throttle((request, callback) => {
        getAutocompleteService()?.getPlacePredictions(request, callback)
      }, 200),
    []
  )

  const getDetails = useMemo(
    () =>
      throttle((request, callback) => {
        getPlacesService()?.getDetails(request, callback)
      }, 200),
    []
  )

  const getAddressSuggestions = useCallback(
    async (
      search,
      options = {
        componentRestrictions: { country: 'us' },
        types: ['address'],
      }
    ) => {
      if (search === '' || !getAutocompleteService()) {
        return []
      }

      return await new Promise((resolve) => {
        getSuggestions({ ...options, input: search }, (results) => resolve(results))
      })
    },
    [getSuggestions]
  )

  const getPlaceDetails = useCallback(
    async (
      placeId,
      options = {
        fields: ['address_components'],
      }
    ) => {
      if (placeId === '' || !getPlacesService()) {
        return null
      }

      return await new Promise((resolve) => {
        getDetails({ ...options, placeId }, (result) => {
          let address1 = '',
            city = '',
            state = '',
            county = '',
            postcode = ''

          for (let component of result.address_components) {
            switch (component.types[0]) {
              case 'street_number':
                address1 = component.long_name + ' ' + address1
                break

              case 'route':
                address1 += component.short_name
                break

              case 'locality':
                city = component.short_name
                break

              case 'administrative_area_level_1':
                state = component.short_name
                break

              case 'administrative_area_level_2':
                county = component.short_name.replace(' County', '')
                break

              case 'postal_code':
                postcode = component.long_name + postcode
                break

              case 'postal_code_suffix':
                postcode = postcode + '-' + component.long_name
                break

              default:
                break
            }
          }

          resolve({ address1, city, state, county, postcode })
        })
      })
    },
    [getDetails]
  )

  return { getAddressSuggestions, getPlaceDetails }
}
