import { Alert, AlertTitle } from '@mui-components'

/**
 * MissingInfo component displays a list of missing information in an alert.
 *
 * @param {object} props - The component props.
 * @param {object} props.data - An object with keys as field names and values as error messages.
 * @returns {JSX.Element|null} The MissingInfo component or null if no data.
 */
export default function MissingInfo({ data }) {
  if (Object.keys(data).length === 0) return null

  return (
    <Alert
      severity="error"
      variant="filled"
      sx={{ mx: 2, mb: 3 }}
      data-testid="missing-information-alert"
      role="alert"
      aria-labelledby="missing-information-title"
    >
      <AlertTitle id="missing-information-title">Missing Information</AlertTitle>
      <ul style={{ paddingInlineStart: '16px' }} data-testid="missing-information-list">
        {Object.values(data).map((v) => (
          <li key={v}>{v}</li>
        ))}
      </ul>
    </Alert>
  )
}
