import { useMemo, useRef } from 'react'
import Cropper from 'react-cropper'
import toast from 'react-hot-toast'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { FlipIcon, RotateLeftIcon, RotateRightIcon } from '@icons'
import { Box, IconButton, Stack } from '@mui-components'

import CropDialog from './CropDialog'

import 'cropperjs/dist/cropper.css'
import './ImageCrop.css'

ImageCrop.propTypes = {
  /** File in base 64 (string) */
  file: PropTypes.string,

  /** Can have a fixed crop shape, allowed values are 'square', 'card', or undefined. Defaults to undefined */
  cropType: PropTypes.string,

  /** Close on save? */
  closeOnSave: PropTypes.bool,

  /** Close on cancel? */
  closeOnCancel: PropTypes.bool,

  /** Can pass mui sx style */
  sx: PropTypes.object,

  /** What to do when click on cancel */
  onCancel: PropTypes.func,

  /** What to do when click on save */
  onSave: PropTypes.func.isRequired,
}

/**
 * ImageCrop component for cropping images with various controls.
 */
export default function ImageCrop({
  file,
  cropType = undefined, // default to free cropping
  closeOnCancel = true,
  onCancel,
  closeOnSave,
  onSave,
  sx = {},
}) {
  const testId = 'image-crop'

  const cropperRef = useRef()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const aspectRatio = useMemo(() => {
    if (cropType === 'card') return 16 / 9
    if (cropType === 'square') return 1
    return NaN // freeform cropping
  }, [cropType])

  const handleSave = async () => {
    const croppedImg = cropperRef.current?.cropper.getCroppedCanvas().toDataURL('image/jpeg')
    if (!croppedImg) return toast.error('There was an issue. Please try again.')

    onSave(croppedImg)
  }

  const handleRotate = (degrees) => {
    // We don't need to worry about going over 360 as the cropper handles that and wraps back to 0
    cropperRef.current?.cropper.rotate(degrees)
  }

  const handleFlipHorizontal = () => {
    const rotation = cropperRef.current?.cropper.getData().rotate

    if (Math.abs(rotation) === 90 || Math.abs(rotation) === 270) {
      // We need to scale the opposite axis when rotated 90 or 270 degrees
      const currentScaleY = cropperRef.current?.cropper.getData().scaleY
      cropperRef.current?.cropper.scaleY(currentScaleY === 1 ? -1 : 1)
      return
    }

    const currentScaleX = cropperRef.current?.cropper.getData().scaleX
    cropperRef.current?.cropper.scaleX(currentScaleX === 1 ? -1 : 1)
  }

  const handleFlipVertical = () => {
    const rotation = Math.abs(cropperRef.current?.cropper.getData().rotate)

    if (Math.abs(rotation) === 90 || Math.abs(rotation) === 270) {
      // We need to scale the opposite axis when rotated 90 or 270 degrees
      const currentScaleX = cropperRef.current?.cropper.getData().scaleX
      cropperRef.current?.cropper.scaleX(currentScaleX === 1 ? -1 : 1)
      return
    }

    const currentScaleY = cropperRef.current?.cropper.getData().scaleY
    cropperRef.current?.cropper.scaleY(currentScaleY === 1 ? -1 : 1)
  }

  return (
    <CropDialog open closeOnCancel={closeOnCancel} closeOnSave={closeOnSave} onCancel={onCancel} onSave={handleSave}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
        role="region"
        aria-label="Image cropping area"
        sx={sx}
      >
        <Cropper
          aspectRatio={aspectRatio}
          autoCrop={true}
          autoCropArea={0.9}
          background={false}
          center={true}
          checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={false}
          initialAspectRatio={aspectRatio}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          viewMode={2}
          responsive={true}
          ref={cropperRef}
          src={file}
          zoomable={true}
          zoomTo={0}
          style={{ height: smallScreen ? '250px' : '400px', width: '100%' }}
          alt="Image cropper"
        />

        <Box sx={{ py: 1, px: 4, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton onClick={() => handleRotate(-90)} data-testid={`${testId}-rotate-left-btn`} aria-label="Rotate left">
            <RotateLeftIcon aria-hidden="true" />
          </IconButton>
          <IconButton
            sx={{ marginRight: 2 }}
            onClick={() => handleRotate(90)}
            data-testid={`${testId}-rotate-right-btn`}
            aria-label="Rotate right"
          >
            <RotateRightIcon aria-hidden="true" />
          </IconButton>
          <IconButton onClick={handleFlipHorizontal} data-testid={`${testId}-flip-x-btn`} aria-label="Flip horizontally">
            <FlipIcon aria-hidden="true" />
          </IconButton>
          <IconButton onClick={handleFlipVertical} data-testid={`${testId}-flip-y-btn`} aria-label="Flip vertically">
            <FlipIcon sx={{ transform: 'rotate(90deg)' }} aria-hidden="true" />
          </IconButton>
        </Box>
      </Stack>
    </CropDialog>
  )
}
