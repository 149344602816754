const styles = {
  dialog: {
    border: '2px solid',
    borderColor: 'primary.main',
  },
  close: {
    position: 'absolute',
    right: 4,
    top: 4,
  },
}

export default styles
