import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import { MeKey } from '@shared/services/src/Users.api'

import PatientsApi from '@services/Patients.api'

export function useVerify() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => PatientsApi.verify(id, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: MeKey(id) }),
  })
}
