import { useMemo } from 'react'

import useQuery from '@shared/hooks/src/useQuery'
import { FeatureFlag, useFeatureFlag } from '@shared/providers/src/FeatureFlagsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import PatientsSharedApi, { PatientTreatmentKeys } from '@shared/services/src/Patients.api'

export default function useTreatments() {
  const me = useMe()
  const id = me?.id

  const { data } = useQuery({
    queryKey: PatientTreatmentKeys.treatmentTypes(id),
    queryFn: () => PatientsSharedApi.treatments(id),
    enabled: Boolean(id),
  })

  return data
}

export function useCurrentTreatmentId(encounterTypeId) {
  const treatments = useTreatments()

  return useMemo(() => {
    if (encounterTypeId) return encounterTypeId

    const currentTreatment = treatments?.find((t) => t.active)
    return currentTreatment.patientEncounterTypes[0]?.id
  }, [encounterTypeId, treatments])
}

// Check if a treatment type is selected
export function useIsTreatmentSelected() {
  const hivTreatmentEnabled = useFeatureFlag(FeatureFlag.PatientSelectTreatment)
  const treatmentTypes = useTreatments()

  return useMemo(() => {
    if (!hivTreatmentEnabled) return true
    return treatmentTypes?.some((treatment) => treatment.active) ?? false
  }, [hivTreatmentEnabled, treatmentTypes])
}
