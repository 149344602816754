import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import PatientsSharedApi from '@shared/services/src/Patients.api'
import { MeKey } from '@shared/services/src/Users.api'

export function useGiveConsent() {
  const me = useMe()

  return useMutation({
    mutationFn: (data) => PatientsSharedApi.giveConsent(me.id, data),
    onSuccess: (updatedData) => {
      // merge current user settings with updated user settings
      return queryClient.setQueryData(MeKey(me.id), (data) => {
        const currentSettings = mapSettings(data.userSettings)
        const updatedSettings = mapSettings(updatedData)
        const settings = { ...currentSettings, ...updatedSettings }
        return { ...data, userSettings: Object.values(settings) }
      })
    },
  })
}

const mapSettings = (settings = []) => settings.reduce((acc, s) => ({ ...acc, [s.id]: s }), {})
