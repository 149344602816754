import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import { HealthHistoryType, keyToString } from '@shared/utils'

import api from './index'

export const HealthHistoryKeys = {
  all: ['health-history'],

  // ['health-history', patientId]
  patient: (patientId) => keyToString([...HealthHistoryKeys.all, patientId]),

  // ['health-history', patientId, 'lists', type]
  lists: (patientId, type) => [...HealthHistoryKeys.patient(patientId), 'lists', type],

  // ['health-history', patientId, 'lists', type, { ...query }]
  list: (patientId, type, query) => [...HealthHistoryKeys.lists(patientId, type), query],
}

export const HealthHistoryNotesKeys = {
  // ['health-history-notes', patientId]
  healthHistory: (patientId) => keyToString(['health-history-notes', patientId]),

  // ['health-history-notes', patientId, 'lists']
  lists: (patientId) => [...HealthHistoryNotesKeys.healthHistory(patientId), 'lists'],

  // ['health-history-notes', patientId, 'lists', { ...query }]
  list: (patientId, query) => [...HealthHistoryNotesKeys.lists(patientId), query],
}

const fabric = (type) => ({
  list: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/${type}`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  create: (patientId, data) =>
    api.post(`/patients/${patientId}/${type}`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  edit: (patientId, id, data) =>
    api
      .patch(`/patients/${patientId}/${type}/${id}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  remove: (patientId, id) =>
    api.delete(`/patients/${patientId}/${type}/${id}`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
})

const HealthHistoryApi = {
  allergies: fabric(HealthHistoryType.Allergies),
  conditions: fabric(HealthHistoryType.Conditions),
  medications: fabric(HealthHistoryType.Medications),
  surgeries: fabric(HealthHistoryType.Surgeries),
  immunizations: fabric(HealthHistoryType.Immunizations),
  vitals: {
    weight: fabric(HealthHistoryType.Vitals),
    height: fabric(HealthHistoryType.Vitals),
    bp: fabric(HealthHistoryType.Vitals),
  },

  notes: (patientId, query) =>
    api
      .get(QS.stringifyUrl({ url: `/patients/${patientId}/health_histories/notes`, query }))
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []),

  createNote: (patientId, message) =>
    api
      .post(`/patients/${patientId}/health_histories/notes`, { message })
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default HealthHistoryApi
