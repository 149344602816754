import { useState } from 'react'

import { useMe } from '@shared/providers/src/MeProvider'

import { AddIcon } from '@icons'
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui-components'
import Insurance from '@components/Card/Insurance'
import Update from '@components/Card/Insurance/Update'
import RenderControl from '@components/RenderControl'
import Transitions from '@components/Transitions'

import MissingInfo from '../components/MissingInfo'
import Title from '../components/Title'
import { useInsuranceCreation, useInsurances } from './Insurance.hooks'
import { getMissingInfo } from './Insurance.utils'

/**
 * Insurances component displays the user's insurance information and allows adding new insurance entries.
 *
 * @param {object} props - The component props.
 * @param {function} props.onClose - Function to call when the component is closed.
 * @returns {JSX.Element} The Insurances component.
 */
export default function Insurances({ onClose }) {
  const me = useMe()
  const [open, setOpen] = useState(false)
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { data, isPending } = useInsurances()
  const create = useInsuranceCreation()

  const isEmpty = !isPending && data?.length === 0

  const missingInfo = getMissingInfo(me, data?.length)

  return (
    <Stack component="main" spacing={2} aria-labelledby="insurances-heading" role="main">
      {isPhone && <Title label="Insurance" onClose={onClose} />}
      <MissingInfo data={missingInfo} />
      <RenderControl isLoading={isPending} isEmpty={isEmpty} emptyTitle={<Empty />} aria-busy={isPending} aria-live="polite">
        <Transitions type="fade" in>
          {data?.map((insurance) => (
            <Insurance key={insurance.id} data={insurance} />
          ))}
        </Transitions>
      </RenderControl>
      <Box sx={{ py: 2, margin: '0 auto' }}>
        <Button
          startIcon={<AddIcon aria-hidden="true" />}
          variant="contained"
          onClick={() => setOpen(true)}
          data-testid="new-insurance"
          aria-label="Add new insurance"
        >
          Add new insurance
        </Button>
      </Box>
      <Update onUpdate={create.mutateAsync} open={open} onClose={() => setOpen(false)} aria-label="Update insurance information" />
    </Stack>
  )
}

/**
 * Empty component displays a message when there are no insurance entries.
 *
 * @returns {JSX.Element} The Empty component.
 */
function Empty() {
  return (
    <Typography variant="h6" align="center" data-testid="no-insurances" component="h2" id="insurances-heading">
      You haven’t added any insurance yet.
    </Typography>
  )
}
