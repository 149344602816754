import { Button, Divider, Stack, Typography } from '@mui-components'

import { Property } from '../HealthHistory.utils'

/**
 * Immunization component displays information about a single immunization.
 *
 * @param {object} props - The component props.
 * @param {object} props.data - The immunization data.
 * @param {boolean} [props.editAllowed=false] - Whether editing is allowed.
 * @param {function} props.onEdit - Function to call when the edit button is clicked.
 * @returns {JSX.Element} The Immunization component.
 */
export default function Immunization({ data, editAllowed = false, onEdit }) {
  const testId = `immunization-${data.name}`

  return (
    <Stack data-testid={testId} role="region" aria-labelledby={`${testId}-name`}>
      <Divider sx={{ mb: 1 }} />
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Typography data-testid={`${testId}-name`} id={`${testId}-name`} component="h3">
          {data.name}
        </Typography>
        {editAllowed && (
          <Button size="small" onClick={onEdit} data-testid={`${testId}-edit`} aria-label={`Edit immunization ${data.name}`}>
            Edit
          </Button>
        )}
      </Stack>
      <Property label="Year" value={data.year} data-testid={testId} />
    </Stack>
  )
}
