import { useMemo } from 'react'

import { getUserSetting } from '@shared/hooks/src/useUserSetting'
import { useMe } from '@shared/providers/src/MeProvider'
import { UserSettingName } from '@shared/utils'

export default function useConsents() {
  const me = useMe()

  return useMemo(() => {
    const agreeToTermsOfService = getUserSetting(me, UserSettingName.AcceptTermsOfService)
    const agreePrivacyPolicy = getUserSetting(me, UserSettingName.AcceptPrivacyPolicy)
    const agreeToTelehealthConsent = getUserSetting(me, UserSettingName.AcceptTelehealthConsent)
    const agreeToShareDataWithCBO = getUserSetting(me, UserSettingName.AllowSharingHealthData)
    const consentToTreat = getUserSetting(me, UserSettingName.Initials)

    return [agreeToTermsOfService, agreePrivacyPolicy, agreeToTelehealthConsent, agreeToShareDataWithCBO, consentToTreat].reduce(
      (acc, setting) => {
        if (setting) acc[setting.name] = setting.value
        return acc
      },
      {}
    )
  }, [me])
}

// Check if all consents are done
export function useConsentsDone() {
  const consents = useConsents()

  return (
    consents[UserSettingName.Initials] &&
    consents[UserSettingName.AllowSharingHealthData] &&
    consents[UserSettingName.AcceptTermsOfService] &&
    consents[UserSettingName.AcceptPrivacyPolicy] &&
    consents[UserSettingName.AcceptTelehealthConsent]
  )
}
