import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import useTheme from '@mui/styles/useTheme'

import Image from '@shared/components/src/Image'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui-components'
import config from '@config'
import NotAuthorizedImage from '@assets/errors/401.webp'
import NotFoundImage from '@assets/errors/404.webp'
import ServerErrorImage from '@assets/errors/500.webp'

ErrorBase.propTypes = {
  /** The title of the page */
  title: PropTypes.string.isRequired,

  /** The subtitle of the page */
  subtitle: PropTypes.string,

  /** HTTP error response code */
  code: PropTypes.oneOf([401, 404, 500]).isRequired,

  /** Details of the error  */
  details: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

const codeToImage = {
  401: NotAuthorizedImage,
  404: NotFoundImage,
  500: ServerErrorImage,
}

/**
 * Base component for handling all error pages with standardizing props and styles
 */
export default function ErrorBase({ title, subtitle, code, details, reset = () => undefined }) {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  const [open, setOpen] = useState(false)

  return (
    <Fade in>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', p: 1, overflow: 'hidden' }}
      >
        {details && <ErrorDialog details={details} open={open} onClose={() => setOpen(false)} />}
        <Grid item xs={12}>
          <Box sx={{ width: { xs: 250, sm: 590 }, height: { xs: 130, sm: 300 }, textAlign: 'center' }}>
            <Hero code={code} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={6} justifyContent="center" alignItems="center">
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center" id="error-title">
                {title}
              </Typography>
              <Typography color="textSecondary" align="center" sx={{ width: '73%' }} id="error-subtitle" aria-labelledby="error-title">
                {subtitle}
              </Typography>
            </Stack>
            <Button component={Link} to={config.defaultPath} variant="contained" onClick={reset} aria-label="Back to home page">
              Back To Home
            </Button>
            {details && (
              <Button color="error" variant="outlined" onClick={() => setOpen(true)} aria-label="View error details">
                View Error
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Fade>
  )
}

function ErrorDialog({ open, onClose, details }) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll="body"
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
      role="alertdialog"
    >
      <DialogTitle id="error-dialog-title">Error Details</DialogTitle>
      <DialogContent dividers id="error-dialog-description">
        <Typography component="pre" variant="body2" tabIndex={0}>
          {details}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="error" onClick={onClose} aria-label="Cancel viewing error details">
          Cancel
        </Button>
        <Button variant="contained" onClick={onClose} aria-label="Ok and close error details">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function Hero({ code }) {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  const fontSize = useMemo(() => (matchDownSM ? 9 : 16), [matchDownSM])

  if (theme.app.default) {
    return <Image src={codeToImage[code]} fit="contain" duration={1000} alt={`Error ${code}`} />
  }

  return (
    <Typography
      variant="h1"
      color={'primary.main'}
      sx={{ fontSize: `${fontSize}rem !important`, fontWeight: 700, lineHeight: `${fontSize}rem` }}
      role="img"
      aria-label={`Error code ${code}`}
    >
      {code}
    </Typography>
  )
}
