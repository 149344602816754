import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogTitle } from '@mui-components'

MedicareDialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Called after any action or the dialog is closed */
  onCancel: PropTypes.func.isRequired,

  /** Called when 'Yes' pressed */
  onConfirm: PropTypes.func.isRequired,

  /** Called when 'No' pressed */
  onReject: PropTypes.func.isRequired,
}

/**
 * MedicareDialog component asks the user if they have Medi-Cal.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {function} props.onCancel - Function to call when the dialog is closed.
 * @param {function} props.onConfirm - Function to call when 'Yes' is pressed.
 * @param {function} props.onReject - Function to call when 'No' is pressed.
 * @returns {JSX.Element} The MedicareDialog component.
 */
export default function MedicareDialog({ open, onCancel, onConfirm, onReject }) {
  const testId = 'medicare-selection-dialog'

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      PaperProps={{ 'data-testid': testId }}
      aria-labelledby={`${testId}-title`}
      role="dialog"
    >
      <DialogTitle id={`${testId}-title`}>Do you have Medi-Cal?</DialogTitle>
      <DialogActions>
        <Button onClick={onReject} data-testid={`${testId}-no`} aria-label="No, I do not have Medi-Cal">
          No
        </Button>
        <Button variant="contained" onClick={onConfirm} data-testid={`${testId}-yes`} aria-label="Yes, I have Medi-Cal">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
