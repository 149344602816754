import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { range } from 'lodash'
import PropTypes from 'prop-types'

import { Box, FormControl, FormHelperText } from '@mui-components'

/**
 * Allowed character types for the OneTimeCode component.
 */
const allowedCharactersValues = ['alpha', 'numeric', 'alphanumeric']

/**
 * Mapping of allowed character types to their respective input attributes.
 */
const propsMap = {
  alpha: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z]{1}',
  },
  alphanumeric: {
    type: 'text',
    inputMode: 'text',
    pattern: '[a-zA-Z0-9]{1}',
  },
  numeric: {
    type: 'tel',
    inputMode: 'numeric',
    pattern: '[0-9]{1}',
    min: '0',
    max: '9',
  },
}

/**
 * OneTimeCode component that renders a series of input fields for entering a one-time code.
 *
 * @component
 * @example
 * const handleCodeChange = (code) => {
 *   console.log('Entered code:', code)
 * }
 * return (
 *   <OneTimeCode
 *     allowedCharacters="numeric"
 *     length={6}
 *     onChange={handleCodeChange}
 *     error="Invalid code entered."
 *     loading={false}
 *     isPassword={false}
 *   />
 * )
 *
 * @param {Object} props - The properties object.
 * @param {string} [props.allowedCharacters='numeric'] - Type of characters allowed ('alpha', 'numeric', 'alphanumeric').
 * @param {string} [props.error] - Error message to display below the input fields.
 * @param {boolean} [props.loading=false] - Whether the input fields are in a loading state.
 * @param {boolean} [props.isPassword=false] - Whether the input fields should mask the entered characters.
 * @param {number} [props.length=6] - Number of input fields to render.
 * @param {Function} props.onChange - Callback function called when the code changes.
 */
const OneTimeCode = forwardRef((props, ref) => {
  const { error, allowedCharacters = 'numeric', loading, isPassword = false, length = 6, onChange } = props

  if (isNaN(length) || length < 1) {
    throw new Error('Length should be a number and greater than 0')
  }

  if (!allowedCharactersValues.includes(allowedCharacters)) {
    throw new Error('Invalid value for allowedCharacters. Use alpha, numeric, or alphanumeric')
  }

  const inputsRef = useRef([])
  const inputProps = propsMap[allowedCharacters]

  /**
   * Exposes imperative methods to parent components.
   * - `focus`: Focuses the first input field.
   * - `clear`: Clears all input fields and focuses the first one.
   */
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputsRef.current) {
        inputsRef.current[0].focus()
      }
    },
    clear: () => {
      if (inputsRef.current) {
        for (let i = 0; i < inputsRef.current.length; i++) {
          inputsRef.current[i].value = ''
        }
        inputsRef.current[0].focus()
      }
      sendResult()
    },
  }))

  // Autofocus the first input on mount
  useEffect(() => {
    if (inputsRef.current) {
      inputsRef.current[0].focus()
    }
  }, [])

  /**
   * Compiles the entered code and invokes the onChange callback.
   */
  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join('')
    if (onChange) onChange(res)
  }

  /**
   * Handles input changes by validating the entered character and moving focus accordingly.
   */
  const handleOnChange = (e) => {
    const {
      target: { value, nextElementSibling },
    } = e
    if (value.length > 1) {
      // If multiple characters are entered, take only the first one
      e.target.value = value.charAt(0)
      if (nextElementSibling !== null) {
        nextElementSibling.focus()
      }
    } else {
      // Validate the entered character against the pattern
      if (value.match(inputProps.pattern)) {
        if (nextElementSibling !== null) {
          nextElementSibling.focus()
        }
      } else {
        e.target.value = ''
      }
    }
    sendResult()
  }

  /**
   * Handles keydown events, specifically the Backspace key for navigating and deleting characters.
   */
  const handleOnKeyDown = (e) => {
    const { key } = e
    const target = e.target
    if (key === 'Backspace') {
      if (target.value === '') {
        // If current input is empty, move focus to the previous input
        if (target.previousElementSibling !== null) {
          const prevInput = target.previousElementSibling
          prevInput.value = ''
          prevInput.focus()
          e.preventDefault()
        }
      } else {
        target.value = ''
      }
      sendResult()
    }
  }

  /**
   * Handles focus events by selecting the input's content.
   */
  const handleOnFocus = (e) => {
    e.target.select()
  }

  /**
   * Handles paste events by distributing pasted characters across input fields.
   */
  const handleOnPaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text')

    let currentInput = 0

    for (let i = 0; i < pastedValue.length; i++) {
      const pastedCharacter = pastedValue.charAt(i)
      const currentValue = inputsRef.current[currentInput].value
      if (pastedCharacter.match(inputProps.pattern)) {
        if (!currentValue) {
          inputsRef.current[currentInput].value = pastedCharacter
          if (inputsRef.current[currentInput].nextElementSibling !== null) {
            inputsRef.current[currentInput].nextElementSibling.focus()
            currentInput++
          }
        }
      }
    }
    sendResult()

    e.preventDefault()
  }

  return (
    <Box>
      <FormControl>
        <Box
          sx={[styles, loading && { borderColor: 'action.disabled' }]}
          data-testid="one-time-code-inputs"
          role="group"
          aria-labelledby="one-time-code-label"
        >
          {range(0, length).map((i) => (
            <input
              key={i}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onFocus={handleOnFocus}
              onPaste={handleOnPaste}
              {...inputProps}
              type={isPassword ? 'password' : inputProps.type}
              ref={(el) => {
                inputsRef.current[i] = el
              }}
              maxLength={1}
              autoComplete={i === 0 ? 'one-time-code' : 'off'}
              aria-label={`Character ${i + 1}.`}
              placeholder="_"
              disabled={loading}
            />
          ))}
        </Box>
        <FormHelperText error={Boolean(error)} sx={{ textAlign: 'center' }} data-testid="one-time-code-error">
          {error}
        </FormHelperText>
      </FormControl>
    </Box>
  )
})

OneTimeCode.propTypes = {
  /** Type of characters allowed in the field */
  allowedCharacters: PropTypes.oneOf(allowedCharactersValues),
  /** Error message to display */
  error: PropTypes.string,
  /** Whether the input fields are in a loading state */
  loading: PropTypes.bool,
  /** Whether the input fields should mask the entered characters */
  isPassword: PropTypes.bool,
  /** Number of input fields to render */
  length: PropTypes.number,
  /** Callback function called when the entered code changes */
  onChange: PropTypes.func.isRequired,
}

const styles = {
  display: 'flex',
  py: '5px',
  px: 2,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'primary.main',
  borderRadius: 1.5,
  gap: 1,
  '& input': {
    padding: 0,
    fontSize: 40,
    fontWeight: 700,
    width: 40,
    textAlign: 'center',
    color: 'text.primary',
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  },
  '& input::placeholder': {
    color: 'text.primary',
    fontSize: 40,
    fontWeight: 300,
  },
  '& input:disabled': {
    color: 'text.disabled',
  },
}

export default OneTimeCode
