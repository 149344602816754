import { includesOneOfErrorMessages, Yup } from '@shared/utils'

export const validationSchema = Yup.object().shape({
  homeAddress: Yup.string().required('Address is required'),
  homeAddress2: Yup.string(),
  homeCity: Yup.string().required('City is required'),
  homeState: Yup.string().required('State is required'),
  homeZip: Yup.string().zip().required('Zip code is required'),
  homeAddressAsShipping: Yup.boolean(),
  shippingAddress: Yup.string().when('homeAddressAsShipping', {
    is: false,
    then: Yup.string().required('Address is required'),
  }),
  shippingAddress2: Yup.string(),
  shippingCity: Yup.string().when('homeAddressAsShipping', {
    is: false,
    then: Yup.string().required('City is required'),
  }),
  shippingState: Yup.string().when('homeAddressAsShipping', {
    is: false,
    then: Yup.string().required('State is required'),
  }),
  shippingZip: Yup.string()
    .zip()
    .when('homeAddressAsShipping', {
      is: false,
      then: Yup.string().required('Zip code is required'),
    }),
})

export const getInitialValues = (user) => {
  const homeAddress = user?.homeAddress?.address || ''
  const homeAddress2 = user?.homeAddress?.address2 || ''
  const homeCounty = user?.homeAddress?.county || ''
  const homeCity = user?.homeAddress?.city || ''
  const homeState = user?.homeAddress?.state || ''
  const homeZip = user?.homeAddress?.zip || ''

  const shippingAddress = user?.shippingAddress?.address || ''
  const shippingAddress2 = user?.shippingAddress?.address2 || ''
  const shippingCity = user?.shippingAddress?.city || ''
  const shippingState = user?.shippingAddress?.state || ''
  const shippingZip = user?.shippingAddress?.zip || ''

  const isShippingAddressSameAsHome =
    Boolean(homeAddress) &&
    Boolean(shippingAddress) &&
    homeAddress === shippingAddress &&
    homeAddress2 === shippingAddress2 &&
    homeCity === shippingCity &&
    homeState === shippingState &&
    homeZip === shippingZip

  return {
    homeAddress,
    homeAddress2,
    homeCounty,
    homeCity,
    homeState,
    homeZip,
    homeAddressAsShipping: isShippingAddressSameAsHome,
    shippingAddress: !isShippingAddressSameAsHome ? shippingAddress : '',
    shippingAddress2: !isShippingAddressSameAsHome ? shippingAddress2 : '',
    shippingCity: !isShippingAddressSameAsHome ? shippingCity : '',
    shippingState: !isShippingAddressSameAsHome ? shippingState : '',
    shippingZip: !isShippingAddressSameAsHome ? shippingZip : '',
  }
}

export const formikToApiData = (values) => {
  const homeAddress = {
    address: values.homeAddress,
    address2: values.homeAddress2,
    county: values.homeCounty,
    city: values.homeCity,
    state: values.homeState,
    zip: values.homeZip,
  }
  const shippingAddress = values.homeAddressAsShipping
    ? homeAddress
    : {
        address: values.shippingAddress,
        address2: values.shippingAddress2,
        city: values.shippingCity,
        state: values.shippingState,
        zip: values.shippingZip,
      }

  return {
    home_address: homeAddress,
    shipping_address: shippingAddress,
  }
}

export const catchZipCodeError = (e) => {
  if (includesOneOfErrorMessages(e, ['Invalid Value for Param: zip'])) {
    return Promise.resolve('invalid')
  }
  return Promise.reject(e)
}

const isValidZipCodeData = (zipCodeData, type, values, setFieldError) => {
  // Validate zip code
  if (zipCodeData === 'invalid') {
    setFieldError(`${type}Zip`, 'Invalid ZIP code')
    return false
  }

  // Validate state
  if ((zipCodeData?.state ?? '') !== values[`${type}State`]) {
    setFieldError(`${type}State`, 'State must belong to entered zip code')
    return false
  }

  return true
}

export const isValidationFailed = (validation, formik) => {
  const [homeValidation, shippingValidation] = validation

  const isValidHomeZip = isValidZipCodeData(homeValidation, 'home', formik.values, formik.setFieldError)
  const isValidShippingZip = formik.values.homeAddressAsShipping
    ? isValidHomeZip
    : isValidZipCodeData(shippingValidation, 'shipping', formik.values, formik.setFieldError)

  return !isValidHomeZip || !isValidShippingZip
}

export const DialogVariant = {
  None: 'none',
  SelectCounty: 'select county',
  SelectCBO: 'select cbo',
  NoCBO: 'no cbo',
  Medicare: 'has medicare',
  CustomerSupport: 'customer support',
}

export const Action = {
  ShowCountySelection: 'showCountySelection',
  ShowCBOSelection: 'showCBOSelection',
  ShowNoCBO: 'showNoCBO',
  ShowMedicare: 'showMedicare',
  ShowCustomerSupport: 'showCustomerSupport',
  CloseDialog: 'closeDialog',
  Processing: 'processing',
  Reset: 'reset',
}

export const initialState = { dialog: DialogVariant.None, counties: [], cbos: [], loading: false }

export const addressFormReducer = (state, action) => {
  switch (action.type) {
    case Action.Processing:
      return { ...state, loading: true }
    case Action.ShowCountySelection:
      return { ...state, dialog: DialogVariant.SelectCounty, counties: action.counties }
    case Action.ShowCBOSelection:
      return { ...state, dialog: DialogVariant.SelectCBO, cbos: action.cbos }
    case Action.ShowNoCBO:
      return { ...state, dialog: DialogVariant.NoCBO }
    case Action.ShowMedicare:
      return { ...state, dialog: DialogVariant.Medicare }
    case Action.ShowCustomerSupport:
      return { ...state, dialog: DialogVariant.CustomerSupport }
    case Action.CloseDialog:
      return { ...state, dialog: DialogVariant.None }
    case Action.Reset:
      return initialState
    default:
      return state
  }
}
