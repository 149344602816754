/* eslint-disable react/forbid-foreign-prop-types */
import React, { useMemo } from 'react'
import { useController } from 'react-hook-form'
import PropTypes from 'prop-types'

import { camelToTitleCase } from '@shared/utils'

HookInputControl.propTypes = {
  /** An input/control component to render */
  children: PropTypes.node.isRequired,

  /** The property name used to provide data to the component */
  field: PropTypes.string.isRequired,

  /** Override the error indicator from outside */
  error: PropTypes.bool,
}

/**
 * A wrapper/helper to use React Hook Form with a component.
 *
 * This will inject a number of properties into the child component that are used
 * by React Hook Form to provide standard behavior.
 * The "field" value will be used for the id, name, label, and data-testid.
 * This wrapper is meant to be used with a FormProvider, so it has access to
 * context and controller.
 *
 * @example
 * <HookInputControl field="name">
 *   <TextField required />
 * </HookInputControl>
 */
export default function HookInputControl({ children, field: name, error, ...rest }) {
  const { field, fieldState } = useController({ name })

  // Pull any known props from the child for re-use
  const childRequired = children.props.required || false
  const childLabel = children.props.label
  const childOnChange = children.props.onChange
  const childOnBlur = children.props.onBlur
  const childHelperText = children.props.helperText
  const childSize = children.props.size || 'small'

  const hasHelperText = children.type.propTypes?.hasOwnProperty('FormHelperTextProps')

  // Generate helper text id
  const helperTextId = `${name}-helper-text`

  // Calculate the label
  const label = useMemo(() => {
    // If label not provided, use the field name
    if (!childLabel) {
      return `${camelToTitleCase(field.name)}`
    }
    // If label is provided and is a string, use it
    if (typeof childLabel === 'string') {
      return `${childLabel}`
    }
    // If label is a component, return it
    return childLabel
  }, [childLabel, field.name])

  const handleBlur = (e) => {
    // Combine child's onBlur handler with React Hook Form's
    if (childOnBlur) childOnBlur(e)
    field.onBlur(e)
  }

  // Determine if there's an error
  const isError = error || (fieldState.isTouched && Boolean(fieldState.error))
  const errorMessage = fieldState.isTouched && fieldState.error?.message

  const additionalProps = {
    required: childRequired,
    variant: 'outlined',
    id: field.name,
    name: field.name,
    value: field.value,
    label,
    size: childSize,
    onChange: childOnChange ?? field.onChange,
    onBlur: handleBlur,
    error: isError,
    helperText: errorMessage || childHelperText,
    inputProps: {
      'data-testid': `input-${field.name}`,
      required: false,
      'aria-describedby': hasHelperText ? helperTextId : undefined,
      'aria-invalid': isError ? 'true' : undefined,
      'aria-required': childRequired ? 'true' : undefined,
      ...children.props.inputProps,
    },
    ...(hasHelperText && {
      FormHelperTextProps: {
        id: helperTextId,
        'data-testid': `helper-${field.name}`,
      },
    }),
    ...rest,
  }

  return React.cloneElement(children, additionalProps)
}
