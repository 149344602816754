import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import AddressesSharedApi from '@shared/services/src/Addresses.api'
import PatientsSharedApi from '@shared/services/src/Patients.api'

export function useZipCodeInfo() {
  return useMutation({
    mutationFn: (zip) => AddressesSharedApi.lookupByZipCode({ zip }),
  })
}

export function useCBOs() {
  const me = useMe()
  return useMutation({
    mutationFn: ({ zip, county }) => PatientsSharedApi.cbos(me.id, { zipcode: zip, county }),
  })
}
