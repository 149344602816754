import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { DatePicker } from '@mui/x-date-pickers-pro'

import { Button, Dialog, LoadingButton, Stack, TextField, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import InputControl from '@components/InputControl'
import { SlideUp } from '@components/Transitions'

import { getInitialValues, toApiData, validationSchema } from './Update.utils'

/**
 * SurgeryUpdate component displays a form to create or edit a surgery.
 *
 * @param {object} props - The component props.
 * @param {object} props.data - The surgery data to edit.
 * @param {function} props.onUpdate - Function to call when the form is submitted.
 * @param {boolean} props.open - Controls whether the dialog is open.
 * @param {function} props.onClose - Function to call when the dialog is closed.
 * @returns {JSX.Element} The SurgeryUpdate component.
 */
export default function SurgeryUpdate({ data, onUpdate, open, onClose }) {
  const isEdit = Boolean(data)
  const dialogTitle = isEdit ? 'Edit Surgery' : 'New Surgery'
  const dialogTitleId = 'surgery-update-dialog-title'

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={SlideUp} aria-labelledby={dialogTitleId} role="dialog">
      <FormHeader title={dialogTitle} onClose={onClose} data-testid="surgery-update-drawer" id={dialogTitleId} />
      <Form surgery={data} onUpdate={onUpdate} onClose={onClose} />
    </Dialog>
  )
}

/**
 * Form component for updating or creating a surgery.
 *
 * @param {object} props - The component props.
 * @param {object} props.surgery - The surgery data.
 * @param {function} props.onUpdate - Function to call when the form is submitted.
 * @param {function} props.onClose - Function to call when the form is closed.
 * @returns {JSX.Element} The Form component.
 */
function Form({ surgery, onUpdate, onClose }) {
  const formik = useFormik({
    initialValues: getInitialValues(surgery),
    validationSchema,
    onSubmit: (values) => onUpdate(toApiData(values)).then(onClose),
  })

  return (
    <form onSubmit={formik.handleSubmit} aria-labelledby="surgery-form-heading">
      <Stack sx={{ maxWidth: 'sm', width: '100%', mx: 'auto', px: 2, py: 3, gap: 3 }}>
        <Typography id="surgery-form-heading" variant="h6" component="h1" sx={{ display: 'none' }}>
          {surgery ? 'Edit Surgery' : 'New Surgery'}
        </Typography>
        <Typography>Once the health history has been updated, any further changes can only be made by your healthcare provider.</Typography>
        <Stack spacing={2}>
          <InputControl field="name" formikProps={formik} variant="standard">
            <TextField
              autoFocus
              required
              fullWidth
              autoComplete="off"
              disabled={formik.isSubmitting}
              inputProps={{ 'aria-label': 'Surgery Name' }}
            />
          </InputControl>
          <DatePicker
            minDate={dayjs('1901-01-01')}
            maxDate={dayjs()}
            value={formik.values.onsetYear}
            onChange={(year) => formik.setFieldValue('onsetYear', year, true)}
            disabled={formik.isSubmitting}
            views={['year']}
            slotProps={{
              textField: {
                label: 'Year',
                fullWidth: true,
                variant: 'standard',
                autoComplete: 'off',
                name: 'onsetYear',
                helperText: formik.touched.onsetYear && formik.errors.onsetYear,
                error: Boolean(formik.touched.onsetYear && formik.errors.onsetYear),
                inputProps: { 'aria-label': 'Select year of surgery' },
              },
            }}
          />
        </Stack>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button disabled={formik.isSubmitting} onClick={onClose} variant="outlined" data-testid="cancel" aria-label="Cancel">
            Cancel
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            type="submit"
            variant="contained"
            data-testid="submit"
            aria-label={surgery ? 'Update surgery' : 'Create surgery'}
          >
            {surgery ? 'Update' : 'Create'}
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  )
}
