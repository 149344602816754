export const outlineRichTextEditorStyling = {
  p: 1,
  border: '1px solid',
  borderColor: 'divider',
  borderRadius: 1,
  '&:focus-within': {
    outline: '1px solid',
    outlineColor: 'primary.main',
    borderColor: 'primary.main',
  },
}
