import { useLayoutEffect } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { useTheme } from '@providers/ThemeProvider'

import { themeUpdater } from './WhiteLabeling.utils'

WhiteLabelingMocked.propTypes = {
  /** The theme to apply */
  theme: PropTypes.object,

  /** The children to render */
  children: PropTypes.node.isRequired,
}

/**
 * A mocked version of the WhiteLabeling provider. This allows passing a theme object
 * that matches what we would get from the API and applying it directing.
 *
 * this allows storybook to provide the white-label theme directly and bypass the API
 */
export default function WhiteLabelingMocked({ theme, children }) {
  const [, setTheme] = useTheme()

  useLayoutEffect(() => {
    const updatedTheme = themeUpdater(theme)
    setTheme(updatedTheme)
  }, [setTheme, theme])

  return (
    <>
      {children}
      <Helmet>{theme.typography.url && <link id="custom-font" rel="stylesheet" href={theme.typography.url} />}</Helmet>
    </>
  )
}
