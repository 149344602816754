import { Fade, Stack, Typography, useMediaQuery } from '@mui-components'

import Title from '../components/Title'
import Allergies from './Allergies'
import Conditions from './Conditions'
import Immunizations from './Immunizations'
import Medications from './Medications'
import Surgeries from './Surgeries'

/**
 * HealthHistoryForm component displays the user's health history, including allergies, conditions, medications, surgeries, and immunizations.
 *
 * @param {object} props - The component props.
 * @param {function} props.onClose - Function to call when the form is closed.
 * @returns {JSX.Element} The HealthHistoryForm component.
 */
export default function HealthHistoryForm({ onClose }) {
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Stack component="main" role="main" aria-labelledby="health-history-form-heading">
        {isPhone && <Title label="Health History" onClose={onClose} />}
        <Typography variant="h5" component="h1" id="health-history-form-heading" sx={{ display: 'none' }}>
          Health History
        </Typography>
        <Stack spacing={4}>
          <Typography variant="subtitle1">
            Please take a moment to review your Health History as displayed below. If there's any new information that isn't currently
            shown, feel free to add it.
          </Typography>
          <Stack spacing={1} pb={4}>
            <Allergies />
            <Conditions />
            <Medications />
            <Surgeries />
            <Immunizations />
          </Stack>
        </Stack>
      </Stack>
    </Fade>
  )
}
