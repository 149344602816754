import { StringParam, useQueryParams } from 'use-query-params'
import { useReadLocalStorage } from 'usehooks-ts'

/**
 * Conditionally returns the theme_id and token from the query params or local storage
 * because the query params are cleared on 'Link Sent' and 'DOB Verify' pages after they being read
 */
export function useThemeArgs() {
  const [{ fa: faParam, token: tokenParam, theme_id: themeIdParam }] = useQueryParams({
    fa: StringParam,
    theme_id: StringParam,
    token: StringParam,
  })

  // DOB verify token
  const faData = useReadLocalStorage('auth-fa-data')

  // Link sent data
  const linkSentData = useReadLocalStorage('auth-link-data')

  return { fa: faParam || faData, token: tokenParam, theme_id: themeIdParam || linkSentData?.theme_id }
}
