import { useMemo } from 'react'

import { isDOMMutationError, isDynamicLoadError } from '@shared/utils'

import ErrorBase from '@components/ErrorBase'

import AppUpdated from './AppUpdated'
import DOMMutation from './DOMMutation'

/**
 * Error component handles different types of errors and displays appropriate messages.
 *
 * @param {object} props - The component props.
 * @param {number} [props.code=500] - The error code.
 * @param {Error|string} props.error - The error object or message.
 * @param {function} props.resetErrorBoundary - Function to reset the error boundary.
 * @returns {JSX.Element} The Error component.
 */
export default function Error({ code = 500, error, resetErrorBoundary }) {
  const details = useMemo(() => {
    if (typeof error === 'string') return error
    return error.message
  }, [error])

  if (isDynamicLoadError(error)) return <AppUpdated />
  if (isDOMMutationError(error)) return <DOMMutation />

  return (
    <ErrorBase
      title="Something isn’t working"
      subtitle="That didn’t work, but we are looking into the problem. Please try again later."
      code={code}
      details={details}
      reset={resetErrorBoundary}
    />
  )
}
