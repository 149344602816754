import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import PatientsSharedApi, { PatientPhotoIdKeys } from '@shared/services/src/Patients.api'

import PatientsApi from '@services/Patients.api'

export function useSendVerification() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => PatientsApi.sendVerification(id, data),
  })
}

export function usePatientUpdatePhotoId() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => PatientsSharedApi.uploadPhotoId(id, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: PatientPhotoIdKeys.photoId(id) }),
  })
}
