import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { AuthGuard } from '@shared/providers/src/MeProvider'

import AuthenticatedProviders from '@providers/AuthenticatedProviders'
import AuthBase from '@pages/Auth/components/AuthBase'
import Error from '@pages/Misc/Error'
import NotAuthorized from '@pages/Misc/NotAuthorized'
import NotFound from '@pages/Misc/NotFound'
import Layout from '@components/Layout'
import { LoadableSuspense } from '@components/Loadable'

import Root from './Root'

const AuthRedirect = LoadableSuspense(lazy(() => import('@pages/AuthRedirect')))
const Home = LoadableSuspense(lazy(() => import('@pages/Home')))
const Environment = LoadableSuspense(lazy(() => import('@pages/Environment')))
const FAQ = LoadableSuspense(lazy(() => import('@pages/FAQ')))
const Enrollment = LoadableSuspense(lazy(() => import('@pages/Auth/Enrollment')))
const AuthVerify = LoadableSuspense(lazy(() => import('@pages/Auth/Login')))
const AuthLinkSent = LoadableSuspense(lazy(() => import('@pages/Auth/LinkSent')))
const AuthLinkRetry = LoadableSuspense(lazy(() => import('@pages/Auth/LinkRetry')))
const ChannelVerified = LoadableSuspense(lazy(() => import('@pages/Auth/ChannelVerified')))

const Appointments = LoadableSuspense(lazy(() => import('@pages/Appointments')))
const Assessments = LoadableSuspense(lazy(() => import('@pages/Assessments')))
const Documents = LoadableSuspense(lazy(() => import('@pages/Documents')))
const Labs = LoadableSuspense(lazy(() => import('@pages/Labs')))
const Messages = LoadableSuspense(lazy(() => import('@pages/Messages')))
const Prescriptions = LoadableSuspense(lazy(() => import('@pages/Prescriptions')))
const Visit = LoadableSuspense(lazy(() => import('@pages/Visit')))
const GileadApplication = LoadableSuspense(lazy(() => import('@pages/GileadApplication')))
const RoiRequest = LoadableSuspense(lazy(() => import('@pages/RoiRequest')))
const Redirect = LoadableSuspense(lazy(() => import('@pages/Redirect')))
const Redirects = LoadableSuspense(lazy(() => import('@pages/Redirects')))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <AuthRedirect />,
      },
      {
        path: '/auth',
        element: (
          <Layout guest>
            <AuthBase />
          </Layout>
        ),
        children: [
          {
            path: 'verify',
            element: <AuthVerify />,
          },
          {
            path: 'sent/:channel',
            element: <AuthLinkSent />,
          },
          {
            path: 'retry',
            element: <AuthLinkRetry />,
          },
          {
            path: 'enrollment',
            element: <Enrollment />,
          },
          {
            path: 'verified',
            element: <ChannelVerified />,
          },
        ],
      },
      /**
       * /app routes accessible only when user is authenticated.
       * AuthorizedProviders wrapper responsible for loading all necessary data before rendering the app
       */ {
        path: '/app',
        element: (
          <AuthGuard>
            <AuthenticatedProviders />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <Layout>
                <Home />
              </Layout>
            ),
          },
          {
            path: 'faq',
            element: (
              <Layout>
                <FAQ />
              </Layout>
            ),
          },
          {
            path: 'messages',
            element: <Messages />,
          },
          {
            path: 'messages/:id',
            element: <Messages />,
          },
          {
            path: 'visits/:id',
            element: <Visit />,
          },
          {
            path: 'labs',
            element: (
              <Layout>
                <Labs />
              </Layout>
            ),
          },
          {
            path: 'prescriptions',
            element: (
              <Layout>
                <Prescriptions />
              </Layout>
            ),
          },
          {
            path: 'appointments',
            element: (
              <Layout>
                <Appointments />
              </Layout>
            ),
          },
          {
            path: 'documents',
            element: (
              <Layout>
                <Documents />
              </Layout>
            ),
          },
          {
            path: 'assessments',
            element: (
              <Layout>
                <Assessments />
              </Layout>
            ),
          },
          {
            path: 'gilead-application/:id',
            element: (
              <Layout>
                <GileadApplication />
              </Layout>
            ),
          },
          {
            path: 'roi-request/:id',
            element: (
              <Layout>
                <RoiRequest />
              </Layout>
            ),
          },
          /**
           * Separate page for handling internal app links.
           * This is used instead of direct links
           * because URLs can change and links will not be valid anymore.
           * Also, this allows us to handle links with additional logic.
           */
          {
            path: 'redirects/:value',
            element: <Redirects />,
          },
        ],
      },
      {
        path: '/environment',
        element: <Environment />,
      },
      /**
       * Acts as a deep link entrypoint.
       * When a user follows a deep link and requires authentication,
       * once auth is completed (admin app) they are sent back here.
       * The url will include a `to` query param with the deep link.
       */ {
        path: '/redirect',
        element: <Redirect />,
      },
      {
        path: '*',
        children: [
          {
            path: '401',
            element: <NotAuthorized />,
          },
          {
            path: '404',
            element: <NotFound />,
          },
          {
            path: '500',
            element: <Error />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
])

export default router
