import { useEffect } from 'react'

import { Logger } from '@shared/utils'

const log = Logger('')

/**
 * Hood to send a page load event to Google Tag Manager.
 * @param {string} page - The page name to send to Google Tag Manager.
 * @param {boolean} enabled - Whether to send the event or not. Useful for dialogs that are loaded, but not shown.
 */
export const useGTPageLoad = ({ page, enabled = true }) => {
  useEffect(() => {
    if (enabled) sendGTEvent({ action: 'page_load', params: { page } })
  }, [page, enabled])
}

/**
 * Send an event to Google Tag Manager.
 * @param {string} action - The action name to send to Google Tag Manager.
 * @param {object} params - The parameters to send to Google Tag Manager.
 */
export const sendGTEvent = ({ action, params }) => {
  // Check that gtag is loaded and ready. We only load in specific environments.
  // In other environments, we do a debug log
  if (window.gtag === undefined) {
    log.debug('gtag disabled in environment:', action, params)
    return
  }

  window.gtag('event', action, params)
}
