export const styles = {
  container: {
    minWidth: 300,
    maxWidth: 400,
    overflow: 'hidden',
    background: (theme) => theme.palette.secondary.extraLight,
    color: '#444',
    border: (theme) => `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 3,
    boxShadow: '0 4px 18px 3px rgba(0, 0, 0, 0.12), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 6px 6px -3px rgba(0, 0, 0, 0.2);',
    px: 2,
    pt: 2,
    pb: 1,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  message: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    '@supports (-webkit-line-clamp: 2)': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'initial',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
    },
  },
}
