import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import PatientsSharedApi, { PatientInsurancesKeys } from '@shared/services/src/Patients.api'

export function useInsurances(options = {}) {
  const { id } = useMe()
  return useQuery({
    queryKey: PatientInsurancesKeys.list(id),
    queryFn: () => PatientsSharedApi.insurances(id),
    ...options,
  })
}

export function useInsuranceCreation() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => PatientsSharedApi.createInsurance(id, data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: PatientInsurancesKeys.lists(id) }),
  })
}
