import toast from 'react-hot-toast'
import { useFormik } from 'formik'

import { diseases, handleError, sexPartners, sexType, std, uniqPartners } from '@shared/utils'

import { Button, Fade, LoadingButton, MenuItem, Stack, TextField, Typography, useMediaQuery } from '@mui-components'
import InputControl from '@components/InputControl'

import Title from '../components/Title'
import { useQuestionnaires, useQuestionnairesUpdate } from './SexualHealth.hooks'
import { formikToApiData, getInitialValues, validationSchema } from './SexualHealth.utils'

/**
 * SexualHealth component allows users to update their sexual health information.
 *
 * @param {object} props - The component props.
 * @param {function} props.onClose - Function to call when the form is closed.
 * @returns {JSX.Element} The SexualHealth component.
 */
export default function SexualHealth({ onClose }) {
  const { data } = useQuestionnaires()
  const update = useQuestionnairesUpdate()

  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(data),
    validationSchema,
    onSubmit: (values) => {
      return update
        .mutateAsync(formikToApiData(values))
        .then(() => toast.success('Sexual Health information updated'))
        .then(onClose)
        .catch(handleError)
    },
  })

  return (
    <Fade in>
      <form noValidate onSubmit={formik.handleSubmit} aria-labelledby="sexual-health-form-heading">
        {isPhone && <Title label="Sexual Health" onClose={onClose} />}
        <Typography variant="h5" component="h1" id="sexual-health-form-heading" sx={{ display: 'none' }}>
          Sexual Health Information
        </Typography>
        <Stack spacing={4} component="main" role="main" aria-labelledby="sexual-health-form-heading">
          <Typography variant="subtitle1">
            Your provider uses this information during your initial visit as part of your examination.
          </Typography>
          <Stack spacing={4}>
            <Typography variant="h6" fontWeight="bold" component="h2">
              Behavior
            </Typography>
            <Stack spacing={2}>
              <InputControl field="hasPrep" formikProps={formik}>
                <TextField
                  label="Have you had a prior PrEP Rx"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Have you had a prior PrEP prescription' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </InputControl>
              <InputControl field="sexPartners" formikProps={formik}>
                <TextField label="Current partners" fullWidth select helperText="Check all that apply" SelectProps={{ multiple: true }}>
                  {sexPartners.map((p) => (
                    <MenuItem key={p} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
              <InputControl field="sexAmount" formikProps={formik}>
                <TextField
                  label="Num of different partners in last 6 months"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Select the number of different partners you have had in the last 6 months' }}
                >
                  {uniqPartners.map((p) => (
                    <MenuItem key={p} value={p}>
                      {p}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
              <InputControl field="sexType" formikProps={formik}>
                <TextField
                  label="Frequent sex positions"
                  fullWidth
                  select
                  helperText="Check all that apply"
                  SelectProps={{ multiple: true }}
                >
                  {Object.entries(sexType).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Stack>
          </Stack>
          <Stack spacing={4}>
            <Typography variant="h6" fontWeight="bold" component="h2">
              Risk Factors
            </Typography>
            <Stack spacing={2}>
              <InputControl field="hivExposure" formikProps={formik}>
                <TextField
                  label="Been exposed to HIV in last 72 hours"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Have you been exposed to HIV in the last 72 hours' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Not Sure">Not Sure</MenuItem>
                </TextField>
              </InputControl>
              <InputControl field="sexCondomless" formikProps={formik}>
                <TextField
                  label="Had unprotected sex in last 6 months"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Have you had unprotected sex in the last 6 months' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Not Sure">Not Sure</MenuItem>
                </TextField>
              </InputControl>
              <InputControl field="sexRisky" formikProps={formik}>
                <TextField
                  label="Partners with HIV in last 6 months"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Have you had partners with HIV in the last 6 months' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Not Sure">Not Sure</MenuItem>
                </TextField>
              </InputControl>
            </Stack>
          </Stack>
          <Stack spacing={4}>
            <Typography variant="h6" fontWeight="bold" component="h2">
              Conditions
            </Typography>
            <Stack spacing={2}>
              <InputControl field="hadStd" formikProps={formik}>
                <TextField
                  label="Chlamydia or Gonorrhea in last 6 months"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Have you had Chlamydia or Gonorrhea in the last 6 months' }}
                >
                  {std.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
              <InputControl field="hadSyphilis" formikProps={formik}>
                <TextField
                  label="Have you had Syphilis in last 6 months"
                  fullWidth
                  select
                  inputProps={{ 'aria-label': 'Have you had Syphilis in the last 6 months' }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </TextField>
              </InputControl>
              <InputControl field="hadDiseases" formikProps={formik}>
                <TextField
                  label="Have you ever been diagnosed with"
                  fullWidth
                  select
                  helperText="Check all that apply"
                  SelectProps={{ multiple: true }}
                >
                  {Object.entries(diseases).map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => formik.resetForm()}
              disabled={!formik.dirty || formik.isSubmitting}
              variant="outlined"
              data-testid="sexual-health-cancel-btn"
              aria-label="Cancel"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={formik.isSubmitting}
              disabled={!formik.dirty}
              type="submit"
              variant="contained"
              data-testid="sexual-health-update-btn"
              aria-label="Update your sexual health information"
            >
              Update
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Fade>
  )
}
