import dayjs from 'dayjs'
import * as Yup from 'yup'

export const getInitialValues = (medication) => ({
  name: medication?.name || '',
  frequency: medication?.frequency || '',
  dosage: medication?.dosage || '',
  year: medication?.year ? dayjs(medication.year) : null,
})

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  frequency: Yup.string().required('Frequency is required'),
  dosage: Yup.string(),
  year: Yup.date()
    .min('1901-01-01', 'Must be after 01/01/1901')
    .max(dayjs().add(1, 'day'), 'Cannot be in the future')
    .nullable()
    .typeError('Invalid Date'),
})

export const toApiData = (values) => ({
  name: values.name,
  frequency: values.frequency,
  dosage: values.dosage,
  year: values.year ? dayjs(values.year).format('YYYY') : null,
})
