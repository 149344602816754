import React from 'react'
import PropTypes from 'prop-types'

import { LinearProgress as LinearProgressMui } from '@mui-components'
import Transitions from '@components/Transitions'

/**
 * LinearProgress component that displays a loading indicator with a fade transition.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.loading - Controls the visibility of the loading indicator.
 * @param {string} [props.color] - Specifies the color of the linear progress bar.
 * @param {Object} [props.sx] - Allows custom styling through MUI's `sx` prop.
 */
LinearProgress.propTypes = {
  /** Controls the visibility of the loading indicator */
  loading: PropTypes.bool,

  /** Color of the linear progress bar */
  color: PropTypes.string,

  /** Custom MUI `sx` styles */
  sx: PropTypes.object,
}

export default function LinearProgress({ loading = false, color, sx = {} }) {
  return (
    <Transitions type="fade" in={loading}>
      <LinearProgressMui
        sx={{ position: 'absolute', width: '100%', zIndex: 1, borderRadius: 0, ...sx }}
        color={color}
        role="progressbar"
        aria-label={loading ? 'Loading' : ''}
        aria-hidden={!loading}
      />
    </Transitions>
  )
}
