import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  social: Yup.string().min(4, 'Please enter valid SSN').max(4, 'Please enter valid SSN').required('Last 4 of SSN is required'),
  income: Yup.number()
    .min(0, 'Please enter a valid income')
    .test('required-insurance', 'CBO is not accepting uninsured patients', (income, context) => {
      return context.parent.insured || context.parent.cboAcceptingUninsured
    })
    .when('insured', {
      is: false,
      then: (schema) => schema.required('Household Income is required'),
    }),
  household: Yup.number()
    .min(0, 'Please enter a valid household size')
    .when('insured', {
      is: false,
      then: (schema) => schema.required('Number of household members is required'),
    }),
  medicare: Yup.boolean(),
  va: Yup.boolean(),
  gilead: Yup.string().max(16, 'Cannot be longer than 16 characters'),
})

export const getInitialValues = (user) => {
  return {
    cboAcceptingUninsured: !(user?.cbo?.cboPreventUninsured ?? true),
    insured: user?.insured ?? false,

    social: user?.social ?? '',
    income: user?.income ?? '',
    household: user?.household ?? '',
    medicare: user?.medicare ?? false,
    va: user?.va ?? false,
    gilead: user?.gilead ?? '',
  }
}

export const formikToApiData = (data) => {
  return {
    patient: {
      social: data.social,
    },
    patient_assistance: {
      income: data.income,
      household: data.household,
      medicare: data.medicare ? 1 : 0,
      va: data.va ? 1 : 0,
      gilead: data.gilead,
    },
  }
}

export const getMissingInfo = (user) => {
  const values = getInitialValues(user)
  const errors = {}
  if (!values.social) {
    errors.social = 'Add your social security number. If you don’t have a ssn please use 1111'
  }
  return errors
}
