import { toast } from 'react-hot-toast'
import { useMutation } from '@tanstack/react-query'
import PubSub from 'pubsub-js'

import { PubSubEvents } from '@shared/hooks/src/usePubSub'
import { useMe } from '@shared/providers/src/MeProvider'
import ConversationsSharedApi from '@shared/services/src/Conversations.api'
import { includesOneOfErrorMessages } from '@shared/utils'

export function useSendMessage() {
  const me = useMe()

  return useMutation({
    mutationFn: ({ message }) => ConversationsSharedApi.sendCSMessage(me.id, { message_text: message }),
    onError: (e) => {
      if (includesOneOfErrorMessages(e, ['Cannot send messages to an archived conversation'])) {
        return toast.error('This conversation has been archived. No new messages can be sent.')
      }
      return toast.error('Unable to send message. Please try again.')
    },
    onSuccess: ({ conversation }) => {
      PubSub.publish(PubSubEvents.ConversationMessageSent, { userId: me.id, conversationId: conversation.id })
      toast.success('Your message was sent')
    },
  })
}
