import defaultsDeep from 'lodash/defaultsDeep'

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 400
const fontWeightBold = 700

export const baseTheme = ({ app, typography } = {}) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '96px !important',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '6px',
          borderRadius: '7px',
        },
        bar: {
          borderRadius: '7px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        sizeMedium: {
          borderRadius: '12px',
        },
        sizeLarge: {
          lineHeight: '38px',
          borderRadius: '27px', // Button default height / 2
          fontSize: '1.25rem',
          padding: '8px 32px',
        },
        outlinedSizeLarge: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
        notchedOutline: {
          borderWidth: '2px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          '&:not(.MuiInputLabel-sizeSmall):not(.MuiInputLabel-shrink)': {
            fontSize: '1.25rem',
            fontWeight: fontWeightRegular,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontWeight: 700,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: fontWeightBold,
          fontSize: '0.875rem',
        },
      },
    },
  },
  direction: 'ltr',
  shape: {
    borderRadius: 8,
  },
  typography: defaultsDeep(typography, {
    htmlFontSize: 16,
    fontFamily: 'proxima-nova, sans-serif',
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
    h1: {
      fontWeight: fontWeightLight,
      fontSize: '6rem', // 96px
      letterSpacing: '-1.5px',
      fontFamily: 'Raleway, sans-serif',
      textTransform: 'capitalize',
    },
    h2: {
      fontWeight: fontWeightLight,
      fontSize: '3.75rem', // 60px
      letterSpacing: '-0.5px',
      fontFamily: 'Raleway, sans-serif',
      textTransform: 'capitalize',
    },
    h3: {
      fontWeight: fontWeightRegular,
      fontSize: '3rem', // 48px
      letterSpacing: '0px',
    },
    h4: {
      fontWeight: fontWeightRegular,
      fontSize: '2.125rem', // 34px
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: fontWeightRegular,
      fontSize: '1.5rem', // 24px
      letterSpacing: '0px',
    },
    h6: {
      fontWeight: fontWeightRegular,
      fontSize: '1.25rem', // 20px
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontWeight: fontWeightRegular,
      fontSize: '1rem', // 16px
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontWeight: fontWeightRegular,
      fontSize: '0.875rem', // 14px
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: fontWeightRegular,
      fontSize: '1rem', // 16px
      letterSpacing: '0.15px',
    },
    body2: {
      fontWeight: fontWeightRegular,
      fontSize: '0.875rem', // 14px
      letterSpacing: '0.15px',
    },
    caption: {
      fontWeight: fontWeightRegular,
      fontSize: '0.75rem', // 12px
      letterSpacing: '0.4px',
    },
    overline: {
      fontWeight: fontWeightRegular,
      fontSize: '0.75rem', // 12px
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: fontWeightBold,
    },
  }),
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  twilio: {
    footerHeight: 72,
    sidebarWidth: 300,
    sidebarMobileHeight: 90,
    sidebarMobilePadding: 8,
    participantBorderWidth: 2,
  },
  app: defaultsDeep(app, {
    default: true,
    name: 'Q Care Plus',
    logo: {
      small: '../assets/logo/small.svg',
      large: '../assets/logo/large.svg',
    },
  }),
})
