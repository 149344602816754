import { useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useReadLocalStorage } from 'usehooks-ts'
import useScrollTrigger from '@mui/material/useScrollTrigger'

import { useMe } from '@shared/providers/src/MeProvider'
import { AuthUtils, BuildEnv } from '@shared/utils'

import { useProfileMissingErrors } from '@pages/Profile'
import { LogoIcon, MenuIcon, ProvidedByQCareIcon } from '@icons'
import { AppBar, Badge, Box, Divider, IconButton, ListItemText, MenuItem, Popover, Stack, Toolbar, Typography } from '@mui-components'
import Avatar from '@components/Avatar'
import Dialog from '@components/Dialog'

import EditProfile from './EditProfile'
import Messages from './Messages'

export default function Header({ hideMenu = false, position = 'fixed' }) {
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 })

  return (
    <>
      <AppBar elevation={trigger ? 6 : 0} position={position} component="header" role="banner" aria-label="Application Header">
        <Toolbar>
          <LogoIcon />
          <Box sx={{ flexGrow: 1 }} />
          {!hideMenu && <Menu />}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  )
}

/**
 * Menu component that displays user options in a popover.
 */
function Menu() {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => setAnchorEl(null)

  return (
    <Wrapper anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <MenuHeader />
      <Divider />
      <Box sx={{ my: 1 }}>
        <EditProfile />
        <Messages />

        <Divider />

        <MenuItem
          component={RouterLink}
          to="/app/labs"
          onClick={handleClose}
          data-testid="labs-menu-item"
          aria-label="Navigate to Lab work"
        >
          <ListItemText primary="Lab work" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/prescriptions"
          onClick={handleClose}
          data-testid="rx-menu-item"
          aria-label="Navigate to Prescriptions"
        >
          <ListItemText primary="Prescriptions" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/appointments"
          onClick={handleClose}
          data-testid="appointments-menu-item"
          aria-label="Navigate to Appointments"
        >
          <ListItemText primary="Appointments" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/documents"
          onClick={handleClose}
          data-testid="documents-menu-item"
          aria-label="Navigate to Documents"
        >
          <ListItemText primary="Documents" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/assessments"
          onClick={handleClose}
          data-testid="assessments-menu-item"
          aria-label="Navigate to Assessments"
        >
          <ListItemText primary="Assessments" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>

        <Divider />
        <MenuItem
          id="faq-menu-item"
          component={RouterLink}
          to="/app/faq"
          onClick={handleClose}
          data-testid="faq-menu-item"
          aria-label="Navigate to FAQ"
        >
          <ListItemText primary="FAQ" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
        </MenuItem>
        <MessageSupportItem />
        <Logout />

        {import.meta.env.VITE_BUILD_ENV !== BuildEnv.Production && (
          <>
            <Divider />
            <MenuItem
              component="a"
              href={`${import.meta.env.VITE_URL}/environment`}
              onClick={handleClose}
              target="_blank"
              rel="noopener"
              aria-label="View Environment (non-production)"
            >
              <ListItemText primary="Environment (non-prod)" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
            </MenuItem>
          </>
        )}

        <Divider />
        <PoweredByQCare />
      </Box>
    </Wrapper>
  )
}

/**
 * Wrapper component that manages the popover state
 */
function Wrapper({ anchorEl, setAnchorEl, children }) {
  const me = useMe()
  const { data } = useProfileMissingErrors()
  const introTour = useReadLocalStorage('intro-tour')

  const notificationsCount = useMemo(() => {
    const profileMissingItemsCount = Object.values(data).reduce((sum, c) => sum + c, 0)
    return profileMissingItemsCount + me.totalUnreadMessageCount
  }, [data, me.totalUnreadMessageCount])

  useEffect(() => {
    // Close the menu if the introduction tour has been passed
    if (introTour?.passed) {
      setAnchorEl(null)
    }
  }, [introTour?.passed, setAnchorEl])

  return (
    <>
      <IconButton
        id="main-menu-button"
        ref={anchorEl}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        size="large"
        color="inherit"
        edge="end"
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}
        data-testid="main-menu-button"
        aria-controls={anchorEl ? 'main-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? 'true' : undefined}
        aria-label="Open main menu"
      >
        <Badge
          color="error"
          badgeContent={notificationsCount}
          max={99}
          data-testid="notifications-count-badge"
          aria-label={`${notificationsCount} new notifications`}
        >
          <MenuIcon fontSize="inherit" aria-hidden="true" />
        </Badge>
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          id: 'main-menu',
          role: 'menu',
          'aria-label': 'Main menu',
          sx: { maxWidth: 350 },
        }}
      >
        {children}
      </Popover>
    </>
  )
}

function MenuHeader() {
  const me = useMe()

  return (
    <Stack sx={{ p: 1, pt: 2, alignItems: 'center', wordBreak: 'break-word', textAlign: 'center' }}>
      <Avatar user={me} variant="rounded" sx={{ mb: 2 }} />
      <Typography
        color="text.primary"
        variant="subtitle2"
        data-testid="menu-patient-fullName"
        aria-label={`Patient name: ${me.fullChosenName}`}
      >
        {me.fullChosenName}
      </Typography>
      <Typography color="textSecondary" variant="subtitle2" data-testid="menu-patient-email" aria-label={`Patient email: ${me.email}`}>
        {me.email}
      </Typography>
    </Stack>
  )
}

function MessageSupportItem() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <MenuItem onClick={() => setOpen(true)} data-testid="message-support-menu-item" aria-label="Contact support">
        <ListItemText primary="Contact support" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
      </MenuItem>
      <Dialog.MessageSupport open={open} onClose={() => setOpen(false)} />
    </>
  )
}

function Logout() {
  return (
    <MenuItem onClick={() => AuthUtils.logout()} data-testid="logout-menu-item" aria-label="Log out of the application">
      <ListItemText primary="Log out" primaryTypographyProps={{ variant: 'body2', color: 'text.primary' }} />
    </MenuItem>
  )
}

function PoweredByQCare() {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1 }}
      component="a"
      href={import.meta.env.VITE_LANDING_PAGE}
      data-testid="landing-link"
      aria-label="Powered by QCare"
    >
      <ProvidedByQCareIcon aria-hidden="true" />
    </Box>
  )
}
