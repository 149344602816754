import React, { useMemo, useState } from 'react'
import toast from 'react-hot-toast'

import { useMe } from '@shared/providers/src/MeProvider'
import { getPhoneMasked, handleError, includesOneOfErrorMessages } from '@shared/utils'

import { Button, Dialog, Stack, Typography } from '@mui-components'
import FormHeader from '@components/Header/Form'
import OneTimeCode from '@components/OneTimeCode'
import { usePopup } from '@components/Popup/Popup'
import { SlideUp } from '@components/Transitions'

import { useSendVerification } from '../Profile.hooks'
import { useVerify } from './PhoneVerification.hooks'
import { popupContent } from './PhoneVerification.utils'

/**
 * PhoneVerification component displays a dialog for verifying the user's phone number by entering a one-time code.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Controls whether the dialog is open.
 * @param {function} props.onClose - Function to call when the dialog is closed.
 * @returns {JSX.Element} The PhoneVerification component.
 */
export default function PhoneVerification({ open, onClose }) {
  const [error, setError] = useState('')

  const me = useMe()
  const resend = useSendVerification()
  const verify = useVerify()
  const onInfo = usePopup({ content: popupContent })

  const phone = useMemo(() => {
    return getPhoneMasked(me.phone)
  }, [me.phone])

  const resendVerification = () => {
    return resend
      .mutateAsync({ channel: 'phone' })
      .then(() => toast.success('Verification code resent'))
      .catch(handleError)
  }

  const handleClose = () => {
    if (error) setError('')
    onClose()
  }

  const handleChange = (code) => {
    if (error) setError('')
    if (code.length === 6) {
      verify
        .mutateAsync({ code, channel: 'phone' })
        .then(() => handleClose())
        .catch((error) => {
          if (includesOneOfErrorMessages(error, ['does not match'])) {
            setError('Sorry, that’s the wrong code. Please try again.')
          } else {
            handleError(error)
          }
        })
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={SlideUp}
      PaperProps={{ 'data-testid': 'verify-phone-dialog' }}
      aria-labelledby="verify-phone-dialog-title"
      aria-describedby="verify-phone-dialog-description"
      role="dialog"
    >
      <FormHeader title="Verify Phone Number" onClose={handleClose} id="verify-phone-dialog-title" />
      <Stack spacing={8} sx={{ pt: 6, px: 3, textAlign: 'center' }}>
        <Stack spacing={2} sx={{ minHeight: 150 }} id="verify-phone-dialog-description">
          <Typography variant="h6">I’ve sent a code to {phone}.</Typography>
          <Typography variant="h6">Enter it below so we make sure we have the correct number.</Typography>
        </Stack>
        <OneTimeCode error={error} loading={verify.isPending} onChange={handleChange} aria-label="Enter verification code" />
        <Stack spacing={4} alignItems="center">
          <Button onClick={() => resendVerification()} data-testid="resend-phone-code">
            Resend the code
          </Button>
          <Button onClick={() => onInfo()} data-testid="missing-phone-code">
            I didn’t get the code
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
