import * as Yup from 'yup'

export const getInitialValues = (allergy) => ({
  name: allergy?.name || '',
  reaction: allergy?.reaction || '',
  severity: allergy?.severity || '',
})

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  reaction: Yup.string().required('Reaction is required'),
  severity: Yup.string().required('Severity is required'),
})

export const toApiData = (values) => ({
  name: values.name,
  reaction: values.reaction,
  severity: values.severity,
})
