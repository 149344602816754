import { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent } from '@mui-components'

CropDialog.propTypes = {
  /** Open dialog? */
  open: PropTypes.bool,

  /** Close on cancel? */
  closeOnCancel: PropTypes.bool,

  /** Close on saving? */
  closeOnSave: PropTypes.bool,

  /** Action to run on cancel */
  onCancel: PropTypes.func,

  /** Action to run on save */
  onSave: PropTypes.func,

  /** Dialog content */
  children: PropTypes.element.isRequired,
}

/**
 * CropDialog component for cropping images or other media.
 */
export default function CropDialog({ open, closeOnCancel, onCancel, closeOnSave, onSave, children = null }) {
  const testId = 'crop-dialog'
  const [modalOpen, setModalOpen] = useState(open)

  if (!children) return null

  const handleCancel = (e) => {
    if (closeOnCancel) setModalOpen(false)
    if (onCancel) onCancel(e)
  }

  const handleSave = (e) => {
    if (closeOnSave) setModalOpen(false)
    if (onSave) onSave(e)
  }

  return (
    <Dialog
      open={modalOpen}
      sx={{ minHeight: '500px!important' }}
      PaperProps={{ 'data-testid': testId }}
      role="dialog"
      aria-modal="true"
      aria-label="Crop Dialog"
    >
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} data-testid={`${testId}-cancel`} aria-label="Cancel cropping">
          Cancel
        </Button>
        <Button onClick={handleSave} type="submit" variant="contained" data-testid={`${testId}-save`} aria-label="Save cropped image">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
