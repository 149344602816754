import { useMemo } from 'react'

import { useMe } from '@shared/providers/src/MeProvider'
import { toTitleCase } from '@shared/utils'

export const useThreadLabel = (users) => {
  const me = useMe()
  return useMemo(() => {
    const myProvider = me.provider
    return users
      .map((u) => {
        if (myProvider && u.id === myProvider.userId) return 'My Provider'
        return toTitleCase(u.role)
      })
      .join(', ')
  }, [me.provider, users])
}
