import { useState } from 'react'

import { useMe } from '@shared/providers/src/MeProvider'

import { EditIcon } from '@icons'
import { Badge, Box, CardActionArea, Stack, Typography, useMediaQuery } from '@mui-components'
import Avatar from '@components/Avatar'

import ProfilePicDialog from './ProfilePicDialog'

/**
 * ProfilePic component that displays the user's profile picture, name, and email.
 * Allows users to update their profile picture through a dialog.
 */
export default function ProfilePic() {
  const me = useMe()

  const [openProfilePicUploader, setOpenProfilePicUploader] = useState(false)
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  return (
    <Stack direction={isTablet ? 'row' : 'column'} spacing={2} alignItems="center">
      <ProfilePicDialog onClose={() => setOpenProfilePicUploader(false)} open={openProfilePicUploader} />
      <CardActionArea
        onClick={() => setOpenProfilePicUploader(true)}
        sx={{ width: 'unset' }}
        data-testid="update-profile-pic"
        aria-label="Update Profile Picture"
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<ProfilePicEditIcon />}
          aria-label="Edit Profile Picture"
        >
          <Avatar
            user={me}
            variant="rounded"
            size="xxl"
            sx={(theme) => ({
              borderRadius: theme.spacing(1.5),
              ...(me.profilePictureThumbUrl ? { border: `1px solid ${theme.palette.primary.main}` } : {}),
            })}
          />
        </Badge>
      </CardActionArea>
      <Stack sx={{ textAlign: isTablet ? 'left' : 'center' }}>
        <Typography component="h2">{me.fullChosenName}</Typography>
        <Typography variant="body2">{me.email}</Typography>
      </Stack>
    </Stack>
  )
}

/**
 * ProfilePicEditIcon component that displays an edit icon over the profile picture.
 */
function ProfilePicEditIcon() {
  return (
    <Box
      aria-hidden="true"
      sx={(theme) => ({
        color: theme.palette.primary.contrastText,
        background: theme.palette.secondary.main,
        width: theme.spacing(3),
        height: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
      })}
    >
      <EditIcon fontSize="18px" aria-hidden="true" />
    </Box>
  )
}
