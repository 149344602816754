import { useState } from 'react'

import { useConversations } from '@shared/messaging/src/hooks'
import { useMe } from '@shared/providers/src/MeProvider'

import { MoreHorizIcon } from '@icons'
import { Button, Divider, Fade, LoadingButton, Stack, Typography } from '@mui-components'
import Dialog from '@components/Dialog'
import SearchInput from '@components/SearchInput'
import Transitions from '@components/Transitions'

import Thread from './Thread'

/**
 * Displays a list of conversation threads with search functionality and options to message support or care team.
 */
export default function Threads({ newPageOnSelect = false }) {
  const [search, updateSearch] = useState('')

  const me = useMe()
  const { data, isPending, fetchNextPage, hasNextPage, isFetchingNextPage } = useConversations(
    {
      user_id: me.id,
      limit: 50,
    },
    { search }
  )

  const showLoading = isPending
  const showEmpty = !isPending && !data?.length && !search
  const showNotFound = !isPending && !data?.length && search
  const showData = !showEmpty && !showNotFound && data?.length > 0

  if (showEmpty) return <Empty />

  return (
    <Stack>
      <Stack spacing={2} sx={{ p: 3, alignItems: 'center' }}>
        <MessageSupport />
        <MessageCareTeam />
        <MessageProvider />
        <SearchInput value={search} onChange={updateSearch} data-testid="search-thread-input" aria-label="Search conversations" />
      </Stack>
      <Divider sx={{ borderColor: 'primary.main' }} />
      {showLoading && (
        <>
          <Thread.Skeleton />
          <Thread.Skeleton />
          <Thread.Skeleton />
        </>
      )}
      {showNotFound && (
        <Fade in>
          <Stack spacing={2} sx={{ p: 3, textAlign: 'center' }} data-testid="threads-not-found" role="status" aria-live="polite">
            <Typography variant="h6">No conversations found</Typography>
          </Stack>
        </Fade>
      )}
      {showData && (
        <Transitions in type="fade" data-testid="conversations" role="region" aria-label="Conversations list">
          {data?.map((thread) => (
            <Thread key={thread.id} highlight={search} newPageOnSelect={newPageOnSelect} data={thread} />
          ))}
          {hasNextPage && (
            <LoadingButton
              fullWidth
              loading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
              endIcon={<MoreHorizIcon aria-hidden="true" />}
              loadingPosition="end"
              sx={{ textTransform: 'none' }}
              aria-label="Load more conversations"
              data-testid="load-more-conversations"
            >
              more
            </LoadingButton>
          )}
        </Transitions>
      )}
    </Stack>
  )
}

/**
 * Displays an empty state with options to start new conversations.
 */
function Empty() {
  return (
    <Fade in>
      <Stack spacing={2} sx={{ p: 3, textAlign: 'center', alignItems: 'center' }} role="status" aria-live="polite">
        <Typography variant="h6">Who would you like to send a message to?</Typography>
        <Stack spacing={2}>
          <MessageSupport />
          <MessageCareTeam />
          <MessageProvider />
        </Stack>
      </Stack>
    </Fade>
  )
}

/**
 * Provides a button to message the user's provider with an accessible dialog.
 */
function MessageProvider() {
  const me = useMe()
  const [open, setOpen] = useState(false)

  if (!me.provider) return null

  return (
    <>
      <Button fullWidth variant="outlined" onClick={() => setOpen(true)} data-testid="message-provider" aria-haspopup="dialog">
        My Provider
      </Button>
      <Dialog.MessageProvider open={open} onClose={() => setOpen(false)} />
    </>
  )
}

/**
 * Provides a button to message customer support with an accessible dialog.
 */
function MessageSupport() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setOpen(true)}
        data-testid="message-support"
        sx={{ px: 0.5 }}
        aria-haspopup="dialog"
      >
        <Stack>
          <Typography variant="body2" fontWeight="bold">
            Message Customer Support
          </Typography>
          <Typography variant="caption">Help with restarting care, insurance, copay, address change, Rx (status, transfer)</Typography>
        </Stack>
      </Button>
      <Dialog.MessageSupport open={open} onClose={() => setOpen(false)} />
    </>
  )
}

/**
 * Provides a button to message the medical assistant with an accessible dialog.
 */
function MessageCareTeam() {
  const [open, setOpen] = useState(false)

  const me = useMe()
  const hasMA = me.provider?.assistantId

  if (!hasMA) return null

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        onClick={() => setOpen(true)}
        data-testid="message-care-team"
        sx={{ px: 0 }}
        aria-haspopup="dialog"
      >
        <Stack>
          <Typography variant="body2" fontWeight="bold">
            Message Medical Assistant
          </Typography>
          <Typography variant="caption">Help with Rx (new fill, refill, prior auth), Labs (status, results)</Typography>
        </Stack>
      </Button>
      <Dialog.MessageCareTeam open={open} onClose={() => setOpen(false)} />
    </>
  )
}
