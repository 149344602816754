import { deserialize } from 'deserialize-json-api'
import QS from 'query-string'

import api from '@shared/services'

const AddressesApi = {
  lookupByZipCode: (query, config) =>
    api
      .get(QS.stringifyUrl({ url: '/addresses/lookup_by_zip_code', query }), config)
      .then((res) => deserialize(res, { transformKeys: 'camelCase' })?.data ?? []),
}

export default AddressesApi
