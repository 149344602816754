import { useEffect } from 'react'
import PubSub from 'pubsub-js'

import { PubSubEvents } from '@shared/hooks/src/usePubSub'

export default function useUserOutOfOfficeListener(user) {
  useEffect(() => {
    if (user?.outOfOfficeMessage) {
      PubSub.publish(PubSubEvents.UserOutOfOffice, [user])
    }
  }, [user])
}
