import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItemButton, ListItemText, Typography } from '@mui-components'

CBODialog.propTypes = {
  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** List of CBOs to display */
  cbos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),

  /** Called after any action or the dialog is closed */
  onCancel: PropTypes.func.isRequired,

  /** Called when selecting a CBO */
  onSelect: PropTypes.func.isRequired,
}

/**
 * CBODialog component displays a dialog allowing the user to select a Community-Based Organization (CBO).
 *
 * @param {object} props - The component props.
 * @param {boolean} props.open - Whether the dialog is open.
 * @param {Array} props.cbos - List of CBOs to display.
 * @param {function} props.onCancel - Function to call when the dialog is closed.
 * @param {function} props.onSelect - Function to call when a CBO is selected.
 * @returns {JSX.Element} The CBODialog component.
 */
export default function CBODialog({ open, cbos = [], onCancel, onSelect }) {
  const testId = 'cbo-selection-dialog'

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="xs"
      PaperProps={{ 'data-testid': testId }}
      aria-labelledby={`${testId}-title`}
      role="dialog"
    >
      <DialogTitle id={`${testId}-title`}>Choose Community Partner</DialogTitle>
      <DialogContent sx={{ px: 1 }}>
        <Typography sx={{ px: 3, py: 1 }}>Your new location requires joining a new community partner that serves your area.</Typography>
        <List aria-label="List of available community partners">
          {cbos.map((cbo, index) => (
            <ListItemButton
              key={cbo.id}
              data-testid={`${testId}-cbo-${index}`}
              onClick={() => onSelect(cbo)}
              aria-label={`Select ${cbo.name}`}
            >
              <ListItemText primary={cbo.name} />
            </ListItemButton>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid={`${testId}-cancel`} aria-label="Cancel and close dialog">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
