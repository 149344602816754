import Highlighter from 'react-highlight-words'
import { Link as RouterLink } from 'react-router-dom'
import dayjs from 'dayjs'

import useIntervalResult from '@shared/hooks/src/useIntervalResult'
import { getThreadName, useThreadUsers } from '@shared/messaging/src/hooks'

import { AvatarGroup, Badge, Box, CardActionArea, Skeleton, Stack, Typography } from '@mui-components'
import Avatar from '@components/Avatar'

import { useThreadLabel } from './Thread.utils'

const styles = {
  container: {
    gap: 2,
    alignItems: 'center',
    p: 3,
    borderBottom: '1px solid',
    borderColor: 'primary.main',
  },
  group: {
    '.MuiAvatarGroup-avatar': {
      width: 32,
      height: 32,
    },
  },
}

/**
 * Displays a thread item with user avatars, thread name, unread messages indicator, and timestamp.
 */
export default function Thread({ data, highlight = '', newPageOnSelect = false }) {
  const users = useThreadUsers(data)
  const name = getThreadName(users)
  const label = useThreadLabel(users)

  return (
    <CardActionArea
      component={RouterLink}
      to={`/app/messages/${data.id}`}
      replace={!newPageOnSelect}
      data-testid={`thread-${name}`}
      aria-labelledby={`thread-${data.id}-name`}
      aria-describedby={`thread-${data.id}-label  thread-${data.id}-timestamp`}
    >
      <Stack direction="row" sx={styles.container}>
        <AvatarGroup max={2} spacing="small" sx={styles.group} aria-label="Thread participants">
          {users.map((user) => (
            <Avatar key={user.id} user={user} size="sm" variant={users.length > 1 ? 'circular' : 'rounded'} />
          ))}
        </AvatarGroup>
        <Stack flexGrow={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Box sx={{ display: 'inline-grid' }}>
              <Typography noWrap id={`thread-${data.id}-name`}>
                <Highlighter autoEscape searchWords={[highlight]} textToHighlight={name} />
              </Typography>
            </Box>
            {data.unreadMessageCount > 0 && (
              <Badge
                color="primary"
                variant="dot"
                data-testid="has-unread"
                aria-label={`${data.unreadMessageCount} unread message${data.unreadMessageCount > 1 ? 's' : ''}`}
              />
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Typography variant="caption" color="text.secondary" id={`thread-${data.id}-label`}>
              {label}
            </Typography>
            <Timing date={data.latest} id={`thread-${data.id}-timestamp`} />
          </Stack>
        </Stack>
      </Stack>
    </CardActionArea>
  )
}

/**
 * Displays the relative time from the current moment to the provided date.
 */
function Timing({ date, id }) {
  const passed = useIntervalResult(() => dayjs(date).fromNow())

  return (
    <Typography variant="caption" color="text.secondary" id={id} aria-label={`Last message ${passed}`}>
      {passed}
    </Typography>
  )
}

Thread.Skeleton = function ThreadSkeleton() {
  return (
    <Stack direction="row" sx={styles.container} aria-busy="true" aria-label="Loading thread">
      <Skeleton variant="circular" width={32} height={32} />
      <Stack flexGrow={1}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography>
            <Skeleton width={200} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
          <Typography variant="caption" color="text.secondary">
            <Skeleton width={100} />
          </Typography>
          <Typography variant="caption" color="text.secondary">
            <Skeleton width={50} />
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
