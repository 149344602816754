import { useInfiniteQuery, useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import HealthHistoryApi, { HealthHistoryKeys } from '@shared/services/src/HealthHistory.api'
import { flatten, handleError, mapCache, pageParam } from '@shared/utils'

const LIMIT = 10

export function useHealthHistory(type, query = {}, options = {}) {
  const me = useMe()
  const extendedQuery = { ...query, limit: LIMIT }

  return useInfiniteQuery({
    queryKey: HealthHistoryKeys.list(me.id, type, extendedQuery),
    queryFn: ({ pageParam }) => HealthHistoryApi[type].list(me.id, { ...extendedQuery, offset: pageParam * LIMIT }),
    ...options,
    select: flatten,
    initialPageParam: 0,
    getNextPageParam: pageParam(LIMIT),
  })
}

export function useEditHealthHistory(type) {
  const me = useMe()
  return useMutation({
    mutationFn: ({ id, data }) => HealthHistoryApi[type].edit(me.id, id, data),
    onError: handleError,
    onSuccess: (data) => {
      return queryClient.setQueriesData(
        { queryKey: HealthHistoryKeys.lists(me.id, type) },
        mapCache((item) => (item.id === data.id ? data : item))
      )
    },
  })
}

export function useCreateHealthHistory(type) {
  const me = useMe()
  return useMutation({
    mutationFn: (data) => HealthHistoryApi[type].create(me.id, data),
    onError: handleError,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: HealthHistoryKeys.lists(me.id, type) }),
  })
}
