import { getTestId } from '@shared/utils'

import { useProfileMissingErrors } from '@pages/Profile'
import {
  AddIcon,
  EmojiPeopleIcon,
  ErrorOutlineIcon,
  HealthAndSafetyOutlinedIcon,
  HelpMoneyIcon,
  KeyboardArrowRightIcon,
  LocationOnIcon,
  MedicalInformationIcon,
  PersonIcon,
} from '@icons'
import { Fade, List, ListItemButton, ListItemIcon, ListItem as ListItemMui, ListItemText, useMediaQuery } from '@mui-components'

import { useProfileItem } from '../../EditProfile.hooks'

/**
 * Menu component that displays a list of profile-related menu items.
 */
export default function Menu() {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const { data } = useProfileMissingErrors()

  return (
    <Fade in>
      <List component="nav" sx={{ minWidth: isTablet ? 220 : '100%', bgcolor: 'background.paper' }} aria-label="Profile Menu">
        <ListItem
          value="profile"
          label="Profile"
          icon={<PersonIcon aria-hidden="true" />}
          error={Boolean(data.profile)}
          data-testid="profile-menu-item"
        />
        <ListItem
          value="address"
          label="Address"
          icon={<LocationOnIcon aria-hidden="true" />}
          error={Boolean(data.address)}
          data-testid="address-menu-item"
        />
        <ListItem
          value="insurance"
          label="Insurance"
          icon={<HealthAndSafetyOutlinedIcon aria-hidden="true" />}
          error={Boolean(data.insurance)}
          data-testid="insurance-menu-item"
        />
        <ListItem
          value="health-assistance"
          label="Health Assistance"
          icon={<HelpMoneyIcon aria-hidden="true" />}
          error={Boolean(data.health)}
          data-testid="health-assistance-menu-item"
        />
        <ListItem
          value="health-history"
          label="Health History"
          icon={<MedicalInformationIcon aria-hidden="true" />}
          data-testid="health-history-menu-item"
        />
        <ListItem value="sexual-health" label="Sexual Health" icon={<AddIcon aria-hidden="true" />} data-testid="sexual-health-menu-item" />
        <ListItem
          value="about"
          label="About Me"
          icon={<EmojiPeopleIcon aria-hidden="true" />}
          error={Boolean(data.about)}
          data-testid="about-me-menu-item"
        />
      </List>
    </Fade>
  )
}

/**
 * ListItem component representing a single item in the Menu.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.label - The display label for the menu item.
 * @param {string} props.value - The value identifier for the menu item.
 * @param {JSX.Element} props.icon - The icon associated with the menu item.
 * @param {boolean} [props.error=false] - Indicates if the menu item has an error.
 * @param {Object} rest - Additional props.
 */
function ListItem({ label, value, icon, error = false, ...rest }) {
  const [profileItem, setProfileItem] = useProfileItem()
  const isTablet = useMediaQuery((theme) => theme.breakpoints.up('sm'))
  const isSelected = profileItem === value

  const testId = getTestId(rest)

  return (
    <ListItemMui disablePadding sx={{ backgroundColor: error ? 'error.shades.light.12' : 'inherit' }} data-testid={testId} role="menuitem">
      <ListItemButton
        selected={isSelected}
        onClick={() => setProfileItem(value)}
        aria-current={isSelected ? 'page' : undefined}
        data-item-selected={isSelected}
      >
        <ListItemIcon>{error ? <ErrorOutlineIcon color="error" aria-hidden="true" /> : icon}</ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ color: error ? 'error' : 'inherit' }} />
        {!isTablet && <KeyboardArrowRightIcon color={error ? 'error' : 'inherit'} aria-hidden="true" focusable="false" />}
      </ListItemButton>
    </ListItemMui>
  )
}
