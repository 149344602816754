import useTheme from '@mui/styles/useTheme'

import { Button, Fade, Grid, Stack, Typography, useMediaQuery } from '@mui-components'

/**
 * AppUpdated component displays a message when new updates have been deployed.
 *
 * @returns {JSX.Element} The AppUpdated component.
 */
export default function AppUpdated() {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fade in>
      <Grid
        container
        spacing={10}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh', p: 1, overflow: 'hidden' }}
        role="main"
        aria-labelledby="app-updated-heading"
      >
        <Grid item xs={12}>
          <Stack spacing={6} justifyContent="center" alignItems="center">
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <Typography variant={matchDownSM ? 'h2' : 'h1'} align="center" component="h1" id="app-updated-heading">
                New Updates Deployed!
              </Typography>
              <Typography color="textSecondary" align="center">
                We've made some improvements.
                <br />
                Reload the page to experience the latest features.
              </Typography>
            </Stack>
            <Button variant="contained" onClick={() => window.location.reload()} aria-label="Reload the page to apply updates">
              Reload
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Fade>
  )
}
