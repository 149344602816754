import { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Logger } from '@shared/utils'

import { Box, CircularProgress, Stack, Typography } from '@mui-components'
import Transitions from '@components/Transitions'

const log = Logger('RenderControl.js')

RenderControl.propTypes = {
  /** Display the loading state */
  isLoading: PropTypes.bool,

  /** Display empty state */
  isEmpty: PropTypes.bool,

  /** Option title to display when isEmpty is true */
  emptyTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /** Can override the color of the spinner, provide a valid MUI color */
  progressColor: PropTypes.string,

  /** Height of the loading or empty state */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /** Children */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

/**
 * A wrapper to manage the display of loading and empty states for async data.
 *
 * @example
 * const {data, isLoading, error} = useQuery(...)
 * return (
 *   <RenderControl isLoading={isLoading} isEmpty={data?.length === 0} emptyTitle="No items to display">
 *     {data?.map((item) => <p>{item.name}</p>)}
 *   </RenderControl>
 */
export default function RenderControl({
  isLoading = false,
  isEmpty = false,
  emptyTitle = '',
  progressColor = 'primary',
  height = '100%',
  children,
}) {
  const content = useMemo(() => {
    if (isLoading && isEmpty) {
      log.warn('Component received true for both loading and isEmpty. This is not supported.')
    }

    if (isEmpty) return <Empty show title={emptyTitle} height={height} />
    if (isLoading) return <Spinner loading progressColor={progressColor} height={height} />
    return children
  }, [children, emptyTitle, height, isEmpty, isLoading, progressColor])

  return (
    <Box
      sx={{ height: '100%', width: '100%', minHeight: 100, position: 'relative' }}
      role="region"
      aria-live={isLoading ? 'polite' : undefined}
      aria-busy={isLoading}
    >
      {content}
    </Box>
  )
}

/**
 * A wrapper component to center its children both vertically and horizontally.
 */
function Wrap({ height, children }) {
  return (
    <Box sx={{ height }} role="presentation">
      <Stack
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        role="none"
        aria-hidden="true"
      >
        {children}
      </Stack>
    </Box>
  )
}

/**
 * Displays an empty state with a title when there is no data.
 */
function Empty({ show = false, title = 'No data', height = '100%', ...rest }) {
  return (
    <Transitions type="fade" in={show} unmountOnExit>
      <Wrap height={height}>
        {typeof title === 'string' ? (
          <Typography variant="h6" color="secondary" {...rest} role="alert" aria-live="assertive">
            {title}
          </Typography>
        ) : (
          title
        )}
      </Wrap>
    </Transitions>
  )
}

/**
 * Displays a loading spinner when data is being fetched.
 */
function Spinner({ loading = false, progressColor, height = '100%', ...rest }) {
  return (
    <Transitions type="fade" in={loading} unmountOnExit>
      <Wrap height={height}>
        <CircularProgress color={progressColor} role="status" aria-live="polite" aria-label="Loading" {...rest} />
      </Wrap>
    </Transitions>
  )
}
