import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import PatientsSharedApi, { PatientQuestionnairesKeys } from '@shared/services/src/Patients.api'

export function useQuestionnaires() {
  const me = useMe()

  return useQuery({
    queryKey: PatientQuestionnairesKeys.questionnaires(me.id),
    queryFn: () => PatientsSharedApi.questionnaires(me.id),
  })
}

export function useQuestionnairesUpdate() {
  const me = useMe()

  return useMutation({
    mutationFn: (data) => PatientsSharedApi.updateQuestionnaires(me.id, data),
    onSuccess: (data) => queryClient.setQueryData(PatientQuestionnairesKeys.questionnaires(me.id), data),
  })
}
