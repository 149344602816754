import { useCallback } from 'react'
import { useModal } from 'mui-modal-provider'
import PropTypes from 'prop-types'

import { getTestId } from '@shared/utils'

import { Button, Dialog, DialogContent, Typography } from '@mui-components'

import styles from './Popup.styles'

Popup.propTypes = {
  /** The content of the popup */
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  /** Whether the modal is shown */
  open: PropTypes.bool.isRequired,

  /** Called when the modal is closed */
  onClose: PropTypes.func.isRequired,
}

/**
 * Popup component displays content in a dialog.
 *
 * @param {object} props - The component props.
 * @returns {JSX.Element} The Popup component.
 */
export default function Popup({ content, open, onClose, ...rest }) {
  const testId = getTestId(rest, 'popup')

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{ 'data-testid': testId, sx: styles.dialog }}
      aria-labelledby={`${testId}-content`}
      role="dialog"
      aria-modal="true"
    >
      <DialogContent sx={{ pt: 6 }}>
        <Button onClick={onClose} data-testid={`${testId}-close`} sx={styles.close} aria-label="Close dialog">
          close
        </Button>
        <div id={`${testId}-content`}>
          {typeof content === 'string' ? <Typography sx={{ whiteSpace: 'pre-line' }}>{content}</Typography> : content}
        </div>
      </DialogContent>
    </Dialog>
  )
}

/**
 * Hook to display a popup dialog.
 *
 * @param {object} props - Props for `<Popup />` component.
 * @returns {function} - A function used to display the dialog.
 *
 * @example
 * const show = usePopup({ content: 'Content' })
 */
export function usePopup(props) {
  const { showModal } = useModal()

  return useCallback(() => {
    return showModal(Popup, props)
  }, [props, showModal])
}
