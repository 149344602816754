import { useState } from 'react'
import debounce from 'lodash/debounce'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import useGooglePlacesAutocomplete from '@shared/hooks/src/useGooglePlacesAutocomplete'
import { Logger } from '@shared/utils'

const log = Logger('AddressField.js')

/**
 * AddressField component provides an autocomplete input for addresses using Google Places API.
 *
 * @param {object} props - The component props.
 * @param {function} props.onChange - Callback function when the address changes.
 * @param {string} [props.helperText] - Helper text for the input field.
 * @param {boolean} [props.error] - Error state of the input field.
 * @param {object} [props.inputProps] - Additional input props.
 * @param {boolean} [props.autoFocus=false] - Autofocus the input field.
 * @returns {JSX.Element} The AddressField component.
 */
export default function AddressField({ onChange, helperText, error, inputProps, autoFocus = false, ...rest }) {
  const [addressOptions, setAddressOptions] = useState([])

  const { getAddressSuggestions, getPlaceDetails } = useGooglePlacesAutocomplete()

  // Typing autocomplete google search
  const handleTypingAddress = debounce((newValue) => {
    getAddressSuggestions(newValue).then((results) => {
      setAddressOptions(newValue.length ? [newValue, ...(results ?? [])] : (results ?? []))
    })
  }, 250)

  // Selecting address in autocomplete
  const handleSelectingAddress = (type, option) => {
    if (!option?.place_id) {
      return onChange({ address: option, city: '', state: '', county: '', zip: '' })
    }

    getPlaceDetails(option.place_id)
      .then(({ address1: address, city, state, county, postcode }) => {
        const zip = postcode.split('-')[0] || ''
        onChange({ address, city, state, county, zip })
      })
      .catch((e) => log.warn(`getPlaceDetails ${option.placeId}`, e))
  }

  return (
    <Autocomplete
      fullWidth
      includeInputInList
      filterSelectedOptions
      autoComplete
      disableClearable
      forcePopupIcon
      freeSolo
      selectOnFocus={false}
      noOptionsText="No matches"
      options={addressOptions}
      filterOptions={(x) => x}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      onChange={(event, option) => handleSelectingAddress('home', option)}
      onInputChange={(event, value) => handleTypingAddress(value)}
      renderOption={({ key, ...props }, option) => {
        const isGooglePlace = option.place_id
        return (
          <li key={`${key}${isGooglePlace ? '' : '_input'}`} {...props}>
            {isGooglePlace ? (
              <Stack>
                <Typography component="span">{option.structured_formatting.main_text}</Typography>
                <Typography variant="body2" color="text.secondary" component="span">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Stack>
            ) : (
              <Typography variant="body2" component="span">
                {option}
              </Typography>
            )}
          </li>
        )
      }}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, inputProps: innerInputProps, ...restParams } = params
        const label = rest.label || 'Address'
        const required = Boolean(rest.required)

        return (
          <TextField
            {...restParams}
            label={label}
            required={required}
            helperText={helperText}
            error={error}
            autoFocus={autoFocus}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            inputProps={{
              ...innerInputProps,
              'aria-label': label,
              ...inputProps,
            }}
          />
        )
      }}
      {...rest}
    />
  )
}
