import ErrorBase from '@components/ErrorBase'

/**
 * NotAuthorized component displays an error message when the user is not authorized to view a page.
 *
 * @param {object} props - The component props.
 * @param {Error|string} props.error - The error object or message.
 * @param {function} props.resetErrorBoundary - Function to reset the error boundary.
 * @returns {JSX.Element} The NotAuthorized component.
 */
export default function NotAuthorized({ error, resetErrorBoundary }) {
  return <ErrorBase title="Sorry" subtitle="You are not allowed to view this page!" code={401} details={error} reset={resetErrorBoundary} />
}
