import { deserialize } from 'deserialize-json-api'

import api from '@shared/services'
import { keyToString, TodoType } from '@shared/utils'

export const PatientTodosKey = (patientId) => keyToString(['todos', patientId])

const PatientsApi = {
  // GET
  todos: (patientId) => {
    return api.get(`/patients/${patientId}/todos`).then((res) => {
      // Deserialize the response data separately for each type of todo item because of special serialization.
      return res.data.map((item) => {
        if ([TodoType.QuestLabOrdered, TodoType.LabsReceived].includes(item.type)) {
          const { serializable_lab: serializableLab, ...rest } = item
          return {
            ...rest,
            lab: deserialize(serializableLab, { transformKeys: 'camelCase' })?.data ?? {},
          }
        }
        if (item.type === TodoType.Appointment) {
          const { serializable_appointment: serializableAppointment, ...rest } = item
          return {
            ...rest,
            appointment: deserialize(serializableAppointment, { transformKeys: 'camelCase' })?.data ?? {},
          }
        }
        if (item.type === TodoType.Insurance) {
          const { serializable_pharmacy: data, ...rest } = item
          const pharmacy = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
          return { ...rest, pharmacy }
        }
        if (item.type === TodoType.Generic) {
          const { serializable_todo: data, ...rest } = item
          const todo = deserialize(data, { transformKeys: 'camelCase' })?.data ?? {}
          return { ...rest, todo }
        }
        return item
      })
    })
  },
  // POST
  uploadProfilePic: (patientId, data) =>
    api
      .post(`/patients/${patientId}/profile_picture`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  sendVerification: (patientId, data, config) =>
    api
      .post(`/patients/${patientId}/verifications`, data, config)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  createEnrollment: (data) =>
    api.post(`/patients/enrollments`, data).then((res) => deserialize(res.data, { transformKeys: 'camelCase' }) ?? {}),

  // PATCH
  verify: (patientId, data, config) => api.patch(`/patients/${patientId}/verifications`, data, config).then((res) => res.data),
  updateEnrollment: (data, config) => {
    return api.patch(`/patients/enrollments`, data, config).then((res) => {
      if (data.enrollment_step === 'last') return res.data
      return deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? []
    })
  },
  updateTodo: (patientId, todoId, data) => api.patch(`/patients/${patientId}/todos/${todoId}`, data).then((res) => res.data),

  // PUT
  updateGileadApplication: (patientId, gileadApplicationId, data) =>
    api
      .put(`/patients/${patientId}/gilead/applications/${gileadApplicationId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
  signGileadApplication: (patientId, gileadApplicationId, data) =>
    api
      .put(`/patients/${patientId}/gilead/applications/${gileadApplicationId}/sign`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  signRoiRequest: (patientId, roiRequestId, data) =>
    api
      .patch(`/patients/${patientId}/roi_requests/${roiRequestId}`, data)
      .then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),

  // DELETE
  deleteProfilePic: (patientId) =>
    api.delete(`/patients/${patientId}/profile_picture`).then((res) => deserialize(res.data, { transformKeys: 'camelCase' })?.data ?? {}),
}

export default PatientsApi
