import * as Yup from 'yup'

export const getInitialValues = (insurance) => {
  return {
    subscriber: insurance?.subscriber || '',
    company: insurance?.company || '',
    idnum: insurance?.idnum || '',
    groupnum: insurance?.groupnum || '',
    bin: insurance?.bin || '',
    pcn: insurance?.pcn || '',
    front: insurance?.front ? `data:image/*;base64,${insurance.front}` : null,
    back: insurance?.back ? `data:image/*;base64,${insurance.back}` : null,
  }
}

export const validationSchema = Yup.object().shape({
  subscriber: Yup.string().required('Insured name is required'),
  company: Yup.string().required('Insurance Company name is required'),
  idnum: Yup.string().required('ID number is required'),
  groupnum: Yup.string(),
  bin: Yup.string(),
  pcn: Yup.string(),
})

export const formikToApiData = (newValues, oldValues) => {
  const values = {
    subscriber: newValues.subscriber,
    company: newValues.company,
    idnum: newValues.idnum,
    groupnum: newValues.groupnum,
    bin: newValues.bin,
    pcn: newValues.pcn,
  }

  // if the image is the same, don't send it
  const front = newValues.front?.split(',')[1]
  if (front !== oldValues?.front) {
    values.front_base64_image = front
    values.front_mime_type = 'image/jpeg'
  }

  // if the image is the same, don't send it
  const back = newValues.back?.split(',')[1]
  if (back !== oldValues?.back) {
    values.back_base64_image = back
    values.back_mime_type = 'image/jpeg'
  }

  return values
}
