import defaultsDeep from 'lodash/defaultsDeep'

const primary = {
  main: '#53b3e4',
  dark: '#1c81b5',
  light: '#abdaf2',
  contrastText: '#FFFFFF',
  contrastTextSecondary: 'rgba(255, 255, 255, 0.72)',
  shades: {
    light: {
      4: 'rgba(37, 58, 122, 0.04)',
      8: 'rgba(7, 58, 122, 0.08)',
      12: 'rgba(7, 58, 122, 0.12)',
      30: 'rgba(7, 58, 122, 0.30)',
      50: 'rgba(7, 58, 122, 0.50)',
    },
  },
}

const secondary = {
  main: '#8AC541',
  dark: '#5C8529',
  light: '#A9FCCA',
  extraLight: '#E2FCEC',
  contrastText: '#FFFFFF',
  shades: {
    light: {
      4: 'rgba(87, 53, 51, 0.04)',
      8: 'rgba(87, 53, 51, 0.08)',
      12: 'rgba(87, 53, 51, 0.12)',
      30: 'rgba(87, 53, 51, 0.3)',
      50: 'rgba(87, 53, 51, 0.5)',
    },
  },
}

const success = {
  main: '#6c9416',
  dark: '#4a660f',
  light: '#8bbf1c',
  contrastText: '#FFFFFF',
  shades: {
    light: {
      50: 'rgba(108, 148, 22, 0.5)',
    },
  },
}

const info = {
  main: '#1f8ec6',
  dark: '#155f84',
  light: '#3da9e1',
  contrastText: '#FFFFFF',
}

const warning = {
  main: '#f3c802',
  dark: '#816A01',
  light: '#fddc44',
  contrastText: '#FFFFFF',
  shades: {
    light: {
      4: 'rgba(253, 220, 68, 0.04)',
      12: 'rgba(253, 220, 68, 0.12)',
      30: 'rgba(253, 220, 68, 0.3)',
      50: 'rgba(253, 220, 68, 0.5)',
    },
  },
}

const error = {
  main: '#253A7A',
  dark: '#17234A',
  light: '#8DA1DD',
  extraLight: '#f8b8d8',
  contrastText: '#FFFFFF',
  shades: {
    light: {
      4: 'rgba(230, 18, 126, 0.04)',
      8: 'rgba(230, 18, 126, 0.08)',
      12: 'rgba(230, 18, 126, 0.12)',
      30: 'rgba(230, 18, 126, 0.3)',
      50: 'rgba(230, 18, 126, 0.5)',
    },
  },
}

const text = {
  primary: 'rgba(0, 0, 0, 0.72)',
  secondary: 'rgba(0, 0, 0, 0.51)',
  disabled: 'rgba(0, 0, 0, 0.3)',
  contrast: '#FFFFFF',
}

const brand = {
  blue: '#253a7a',
  cyan: '#45baeb',
  lime: '#8ac541',
  purple: '#6a439a',
  magenta: '#dc258b',
  yellow: '#fbdc0d',
  grey: '#636569',
}

const background = {
  default: '#FFFFFF',
  paper: '#FFFFFF',
  backdrop: 'rgba(255, 255, 255, 0.7)',
}

const action = {
  active: 'rgba(0, 0, 0, 0.54)',
  hover: 'rgba(0, 0, 0, 0.04)',
  selected: 'rgba(83, 179, 228, 0.08)',
  disabled: 'rgba(0, 0, 0, 0.26)',
  disabledBackground: 'rgba(0, 0, 0, 0.12)',
  focus: 'rgba(0, 0, 0, 0.12)',
}

const divider = 'rgba(37, 58, 122, 0.2)'

const defaultPalette = {
  primary,
  secondary,
  success,
  info,
  warning,
  error,
  text,
  brand,
  background,
  action,
  divider,
}

export const lightTheme = ({ palette } = {}) => {
  // Merge the provided palette with the default one to ensure all values are present
  const newPalette = defaultsDeep({ mode: 'light' }, palette, defaultPalette)
  const { primary, success, warning, text, background } = newPalette

  return {
    palette: newPalette,
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:not(.Mui-error)': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: primary.main,
              },
              '&:hover': {
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: primary.main,
                },
              },
              '&.Mui-disabled': {
                color: primary.shades.light[12],
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: primary.shades.light[12],
                },
              },
              '.MuiInputAdornment-root': {
                color: primary.light,
              },
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            '&:not(.Mui-error)': {
              color: primary.light,
              '&.MuiInputLabel-shrink': {
                color: `${primary.main} !important`,
              },
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          colorPrimary: {
            color: primary.main,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: primary.shades.light[12],
              '&:hover': {
                color: primary.dark,
                backgroundColor: primary.shades.light[12],
              },
            },
            '&:hover': {
              color: primary.dark,
              backgroundColor: 'transparent',
            },
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained', color: 'warning' },
            style: {
              color: text.secondary,
            },
          },
          {
            props: { variant: 'outlined', color: 'warning' },
            style: {
              color: text.secondary,
            },
          },
          {
            props: { variant: 'text', color: 'warning' },
            style: {
              color: warning.dark,
            },
          },
        ],
      },
      MuiChip: {
        variants: [
          {
            props: { color: 'warning' },
            style: {
              color: success.dark,
            },
          },
        ],
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            color: text.primary,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            '&:not(.MuiBackdrop-invisible)': {
              backgroundColor: background.backdrop,
            },
          },
        },
      },
    },
  }
}
