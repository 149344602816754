import { useMutation } from '@tanstack/react-query'

import useQuery from '@shared/hooks/src/useQuery'
import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import PatientsSharedApi, { PatientCbosKeys, PatientTreatmentKeys } from '@shared/services/src/Patients.api'

export function useTreatmentUpdate() {
  const { id } = useMe()

  return useMutation({
    mutationFn: (data) => PatientsSharedApi.updateTreatment(id, data),
    onSuccess: (data) =>
      queryClient.setQueryData(PatientTreatmentKeys.treatmentTypes(id), (oldData = []) =>
        oldData.filter((treatment) => treatment.id !== data.id).concat(data)
      ),
  })
}

export function useCBOServices() {
  const me = useMe()
  const patientId = me.id
  const patientCboId = me.cbo?.id

  const { data } = useQuery({
    queryKey: PatientCbosKeys.list(patientId),
    queryFn: () => PatientsSharedApi.cbos(patientId),
    enabled: Boolean(patientCboId),
    select: (data) => (data.find((cbo) => cbo.id === patientCboId)?.cbosProviderTypes || []).map((providerType) => providerType.name),
  })

  return data
}
