import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'

import { useMe } from '@shared/providers/src/MeProvider'
import { queryClient } from '@shared/providers/src/QueryClientProvider'
import PatientsSharedApi from '@shared/services/src/Patients.api'
import { MeKey } from '@shared/services/src/Users.api'

import { getMissingInfo as getAboutMeMissingInfo } from './AboutMe/AboutMe.utils'
import { getMissingInfo as getHealthMissingInfo } from './HealthAssistance/HealthAssistance.utils'
import { useInsurances } from './Insurance/Insurance.hooks'
import { getMissingInfo as getInsuranceMissingInfo } from './Insurance/Insurance.utils'
import { getMissingInfo as getProfileMissingInfo } from './Profile/Profile.utils'

export function usePatientUpdate() {
  const { id } = useMe()
  return useMutation({
    mutationFn: (data) => PatientsSharedApi.update(id, data),
    onSuccess: (patient) => queryClient.setQueryData(MeKey(id), (prev = {}) => ({ ...prev, ...patient })),
  })
}

export function useProfileMissingErrors() {
  const me = useMe()

  // Works only with the cache
  const { data: insurances, isPending } = useInsurances({ enabled: false })

  const data = useMemo(() => {
    const data = {}

    const profileSectionMissingInfo = getProfileMissingInfo(me)
    data.profile = Object.keys(profileSectionMissingInfo).length

    const aboutSectionMissingInfo = getAboutMeMissingInfo(me)
    data.about = Object.keys(aboutSectionMissingInfo).length

    const healthSectionMissingInfo = getHealthMissingInfo(me)
    data.health = Object.keys(healthSectionMissingInfo).length

    const insuranceSectionMissingInfo = getInsuranceMissingInfo(me, insurances?.length)
    data.insurance = Object.keys(insuranceSectionMissingInfo).length

    return data
    // Add photoId to track changes in packages/apps/patient/src/providers/MeProvider/MeProvider.js#L24
    // made to photoId dynamically added/loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurances?.length, me, me?.photoId])

  return { data, isPending }
}
