import JoinAppointment from './JoinAppointment'
import Messages from './Messages'
import OutOfOffice from './OutOfOffice'

export default function Notifications() {
  return (
    <>
      <Messages />
      <OutOfOffice />
      <JoinAppointment />
    </>
  )
}
