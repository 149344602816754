import { useMemo } from 'react'
import toast from 'react-hot-toast'
import { useFormik } from 'formik'
import { range } from 'lodash'

import { Lookup, useLookup } from '@shared/providers/src/DropdownOptionsProvider'
import { useMe } from '@shared/providers/src/MeProvider'
import { ethnicities, handleError, races, relationships, sexualities } from '@shared/utils'

import { Box, Button, Fade, Grid, InputAdornment, LoadingButton, MenuItem, Stack, TextField, useMediaQuery } from '@mui-components'
import InputControl from '@components/InputControl'

import MissingInfo from '../components/MissingInfo'
import Title from '../components/Title'
import { usePatientUpdate } from '../Profile.hooks'
import { formikToApiData, getInitialValues, getMissingInfo, validationSchema } from './AboutMe.utils'

/**
 * AboutMeForm component allows users to update their personal information.
 *
 * @param {object} props - The component props.
 * @param {function} props.onClose - Function to call when the form is closed.
 * @returns {JSX.Element} The AboutMeForm component.
 */
export default function AboutMeForm({ onClose }) {
  const me = useMe()
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const genderList = useLookup(Lookup.GenderList)
  const sexList = useLookup(Lookup.SexList)

  const update = usePatientUpdate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(me),
    validationSchema,
    onSubmit: (values) =>
      update
        .mutateAsync(formikToApiData(values))
        .then(() => toast.success('About Me information updated'))
        .then(onClose)
        .catch(handleError),
  })

  const missingInfo = useMemo(() => getMissingInfo(me), [me])

  return (
    <Fade in>
      <form noValidate onSubmit={formik.handleSubmit} aria-labelledby="about-me-form-heading">
        {isPhone && <Title label="About me" onClose={onClose} />}
        <MissingInfo data={missingInfo} />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }} role="main" aria-labelledby="about-me-form-heading">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputControl field="height" error={Boolean(missingInfo.height)} formikProps={formik}>
                <TextField fullWidth select required inputProps={{ 'aria-label': 'Select your height in feet and inches' }}>
                  {range(57, 85).map((i) => (
                    <MenuItem key={i} value={i}>
                      {Math.floor(i / 12)}' {i % 12}"
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="weight" error={Boolean(missingInfo.weight)} formikProps={formik}>
                <TextField
                  fullWidth
                  type="number"
                  required
                  InputProps={{ endAdornment: <InputAdornment position="end">lbs</InputAdornment> }}
                  inputProps={{ min: 0, 'aria-label': 'Enter your weight in pounds' }}
                />
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="gender" error={Boolean(missingInfo.gender)} formikProps={formik}>
                <TextField required fullWidth select inputProps={{ 'aria-label': 'Select your gender identity' }}>
                  {genderList.map((g) => (
                    <MenuItem key={g} value={g}>
                      {g}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={6}>
              <InputControl field="bornAs" error={Boolean(missingInfo.bornAs)} formikProps={formik}>
                <TextField required fullWidth select inputProps={{ 'aria-label': 'Select the sex assigned to you at birth' }}>
                  {sexList.map((b) => (
                    <MenuItem key={b} value={b}>
                      {b}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControl field="sexuality" error={Boolean(missingInfo.sexuality)} formikProps={formik}>
                <TextField required fullWidth select inputProps={{ 'aria-label': 'Select your sexual orientation' }}>
                  {sexualities.map((s) => (
                    <MenuItem key={s} value={s}>
                      {s}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={12}>
              <InputControl field="relationship" formikProps={formik}>
                <TextField fullWidth select inputProps={{ 'aria-label': 'Select your relationship status' }}>
                  {relationships.map((e) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputControl field="race" error={Boolean(missingInfo.race)} formikProps={formik}>
                <TextField fullWidth select SelectProps={{ multiple: true }} required inputProps={{ 'aria-label': 'Select your race' }}>
                  {races.map((r) => (
                    <MenuItem key={r} value={r}>
                      {r}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
            <Grid item xs={12}>
              <InputControl field="ethnicity" error={Boolean(missingInfo.ethnicity)} formikProps={formik}>
                <TextField fullWidth select required inputProps={{ 'aria-label': 'Select your ethnicity' }}>
                  {ethnicities.map((e) => (
                    <MenuItem key={e} value={e}>
                      {e}
                    </MenuItem>
                  ))}
                </TextField>
              </InputControl>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={() => formik.resetForm()}
              disabled={!formik.dirty || formik.isSubmitting}
              variant="outlined"
              data-testid="about-me-cancel-btn"
              aria-label="Cancel"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.dirty}
              type="submit"
              variant="contained"
              data-testid="about-me-update-btn"
              aria-label="Update your information"
            >
              Update
            </LoadingButton>
          </Stack>
        </Box>
      </form>
    </Fade>
  )
}
