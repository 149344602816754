import React, { useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { getTestId, toTitleCase } from '@shared/utils'

import { ArrowDropDownIcon, ArrowDropUpIcon } from '@icons'
import { Button, Collapse, IconButton, Skeleton, Stack, Typography } from '@mui-components'

export const styles = {
  card: {
    p: 2,
    minHeight: 78,
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'primary.main',
  },
}

/**
 * Property component displays a label and value pair.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label of the property.
 * @param {string} props.value - The value of the property.
 * @returns {JSX.Element} The Property component.
 */
export const Property = ({ label, value, ...rest }) => {
  const testId = getTestId(rest, 'property')

  return (
    <Stack direction="row" data-testid={`${testId}-property`}>
      <Typography component="dt" variant="caption" color="text.secondary" sx={{ minWidth: 70 }} data-testid={`${testId}-property-${label}`}>
        {label}
      </Typography>
      <Typography component="dd" variant="body2" noWrap data-testid={`${testId}-property-${label}-${value}`}>
        {value || '-'}
      </Typography>
    </Stack>
  )
}

/**
 * BaseCard component displays a card with collapsible content and actions.
 *
 * @param {object} props - The component props.
 * @param {Array} props.items - The items to display.
 * @param {boolean} props.hasMore - Indicates if there are more items.
 * @param {string} props.name - The name of the card.
 * @param {function} props.onAdd - Function to call when the add button is clicked.
 * @param {ReactNode} props.children - The collapsible content.
 * @returns {JSX.Element} The BaseCard component.
 */
export function BaseCard({ items = [], hasMore, name, onAdd, children }) {
  const [isCollapsed, setIsCollapsed] = useState(false)

  const testId = `card-${name}`

  const count = items.length
  const lastUpdatedItem = useMemo(() => {
    return items.reduce((oldestItem, currentItem) => {
      if (Date.parse(currentItem.updatedAt) > Date.parse(oldestItem.updatedAt)) {
        return currentItem
      } else {
        return oldestItem
      }
    }, items[0])
  }, [items])

  if (items.length === 0) return <Empty name={name} onAdd={onAdd} />

  const collapseId = `${testId}-collapse-content`

  return (
    <Stack sx={styles.card} data-testid={testId} role="region" aria-labelledby={`${testId}-title`}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
        <Stack>
          <Stack direction="row" gap={1}>
            <Typography data-testid={`${testId}-title`} id={`${testId}-title`} component="h2">
              {toTitleCase(name)}
            </Typography>
            <Typography data-testid={`${testId}-count`}>{`(${count}${hasMore ? '+' : ''})`}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="text.secondary">
              Last updated:
            </Typography>
            <Typography variant="caption" data-testid={`${testId}-last-updated`}>
              {lastUpdatedItem ? dayjs(lastUpdatedItem.updatedAt).format('LL') : '-'}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Button size="small" onClick={onAdd} data-testid={`${testId}-new`} aria-label={`Add new ${name}`}>
            Add
          </Button>
          <IconButton
            size="small"
            onClick={() => setIsCollapsed((prev) => !prev)}
            data-testid={`${testId}-collapse-toggle`}
            aria-label={isCollapsed ? `Collapse ${name} items` : `Expand ${name} items`}
            aria-expanded={isCollapsed}
            aria-controls={collapseId}
          >
            {isCollapsed ? <ArrowDropUpIcon aria-hidden="true" /> : <ArrowDropDownIcon aria-hidden="true" />}
          </IconButton>
        </Stack>
      </Stack>
      <Collapse in={isCollapsed}>
        <Stack id={collapseId} spacing={1} pt={1}>
          {children}
        </Stack>
      </Collapse>
    </Stack>
  )
}

BaseCard.Loading = () => <Skeleton variant="rounded" height={78} />

/**
 * Empty component displays a message when there are no items and an option to add new items.
 *
 * @param {object} props - The component props.
 * @param {string} props.name - The name of the items.
 * @param {function} props.onAdd - Function to call when the add button is clicked.
 * @returns {JSX.Element} The Empty component.
 */
const Empty = ({ name, onAdd }) => (
  <Stack
    direction="row"
    spacing={1}
    alignItems="center"
    justifyContent="space-between"
    sx={styles.card}
    data-testid={`empty-${name}`}
    role="region"
    aria-labelledby={`empty-${name}-title`}
  >
    <Typography id={`empty-${name}-title`}>
      You don’t have any <b>{name}</b>
    </Typography>
    <Button size="small" onClick={onAdd} data-testid={`empty-${name}-add`} aria-label={`Add new ${name}`}>
      Add
    </Button>
  </Stack>
)
