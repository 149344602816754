import { CloseIcon } from '@icons'
import { ListItem, ListItemButton, ListItemText } from '@mui-components'

/**
 * Title component displays a title with a close button.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The title label.
 * @param {function} props.onClose - Function to call when the close button is clicked.
 * @returns {JSX.Element} The Title component.
 */
export default function Title({ label, onClose }) {
  return (
    <ListItem disablePadding onClick={onClose} sx={{ mb: 3 }} data-testid={`${label}-close`} role="button" aria-label={`Close ${label}`}>
      <ListItemButton>
        <ListItemText primary={label} primaryTypographyProps={{ variant: 'h6', fontWeight: 'bold', component: 'h2' }} />
        <CloseIcon color="primary" aria-hidden="true" />
      </ListItemButton>
    </ListItem>
  )
}
