import dayjs from 'dayjs'
import * as Yup from 'yup'

export const getInitialValues = (condition) => ({
  name: condition?.name || '',
  onsetYear: condition?.onsetYear ? dayjs(condition.onsetYear, 'YYYY') : null,
})

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  onsetYear: Yup.date()
    .min('1901-01-01', `Must be greater than 1901`)
    .max(dayjs().add(1, 'year'), 'Cannot be in the future')
    .nullable()
    .typeError('Invalid Date'),
})

export const toApiData = (values) => ({
  name: values.name,
  onset_year: values.onsetYear ? dayjs(values.onsetYear).format('YYYY') : null,
})
