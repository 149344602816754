import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LoadingButton, Stack, Typography } from '@mui-components'

/**
 * ConfirmInjectablePrEP component displays a confirmation dialog for enrolling in Injectable PrEP care.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.loading - Indicates whether the confirm action is loading.
 * @param {function} props.onCancel - Function to call when the cancel button is clicked.
 * @param {function} props.onConfirm - Function to call when the confirm button is clicked.
 * @returns {JSX.Element} The ConfirmInjectablePrEP component.
 */
export default function ConfirmInjectablePrEP({ loading, onCancel, onConfirm }) {
  const navigate = useNavigate()

  const handleSchedule = async () => {
    onConfirm({ patient_choice: 'schedule_appointment_partner' }).then(() => {
      toast.success('We will be reaching out shortly to setup your appointment')
    })
  }

  const handleMoreInfo = async () => {
    onConfirm({ patient_choice: 'schedule_appointment_platform' }).then(() => {
      navigate('/app/appointments?action=schedule-appointment')
    })
  }

  return (
    <Dialog open fullWidth maxWidth="xs" scroll="body" aria-labelledby="confirm-injectable-prep-dialog-title" role="dialog">
      <DialogTitle id="confirm-injectable-prep-dialog-title">Enroll in Injectable PrEP Care</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography fontWeight="bold">PrEP involves oral or injectable medication that prevents HIV infection.</Typography>
          <Typography variant="body2">
            <b>Administration:</b>
            <br />A shot is given in the muscle of the buttocks by a trained nurse or provider. To start injectable PrEP, you will need one
            shot, followed by a second shot one month later. After that you will receive one shot every two months.
          </Typography>
          <Typography variant="body2">
            <b>Ongoing Care:</b>
            <br />Q Care Plus will coordinate your in-person injections and send you reminders. You will remain in touch with your provider,
            who will check-in prior to your injections and as needed. Your Provider will offer STI prevention counseling, Doxy-PEP and/or
            STI treatment when needed.
          </Typography>
          <Typography variant="body2">
            <b>Adherence is Key:</b>
            <br />
            Your Provider will help you manage planned or unplanned late or missed injections. Contact your Provider if you anticipate any
            issues.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ pb: 3, px: 3 }}>
        <Stack spacing={1} sx={{ width: '100%' }}>
          <LoadingButton
            onClick={handleSchedule}
            variant="contained"
            loading={loading}
            data-testid="injectable-prep-confirm"
            aria-label="Schedule appointment for Injectable PrEP"
          >
            Schedule Appointment
          </LoadingButton>
          <LoadingButton
            onClick={handleMoreInfo}
            variant="contained"
            loading={loading}
            data-testid="injectable-prep-more-info"
            aria-label="Get more information on Injectable PrEP"
          >
            More Info on Injectable PrEP
          </LoadingButton>
          <Button onClick={onCancel} disabled={loading} data-testid="back" aria-label="Go back to treatment selection">
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
